import React, { useEffect, useMemo } from "react";

import { Box, Card } from "@material-ui/core";

import Stepper from "@remar/shared/dist/components/Stepper";

import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { getFullName } from "@remar/shared/dist/utils/auth";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState } from "store/features/Auth/authSlice";
import { getPaymentDetails, getFullState as selectBillingFullState } from "store/features/Billing/BillingSlice";
import {
	buyGiftCard,
	buyGiftCardAsGuest,
	findGiftCard,
	selectGiftCardFullState,
	setError
} from "store/features/GiftCard/giftCard.slice";
import { UserSignUpVerificationDto } from "store/services";

import OrderGiftCardSummary from "./OrderGiftCardSummary";

import { routes } from "../../core/constants";
import SignUpLayout from "../Auth/SignUpLayout";
import { SimpleFormActions } from "../Auth/components/FormActions";
import { PaymentForm, Recipient } from "../Auth/components/Forms";
import { GuestSignUpError } from "../Auth/components/GuestSignUpError";
import { PaymentSchema, RecipientSchema } from "../Auth/components/schemas";
import { useStyles } from "../Auth/components/styles";
import PaymentMethods from "../Billings/PaymentMethods";

const steps = [{ label: "Recipient" }, { label: "Payment" }];
enum Steps {
	Recipient = 0,
	Payment = 1,
	Error = 2
}

const initialFormValues = {
	recipientName: "",
	recipientEmail: "",
	message: "",
	gifterName: "",
	gifterEmail: ""
};

const OrderGiftCard = () => {
	const dispatch = useAppDispatch();
	const classes = useStyles();
	const history = useHistory();
	const elements = useElements();
	const stripe = useStripe();
	const { giftId } = useParams();

	const {
		location: { state }
	} = history;

	const [gift, setGift] = React.useState(state?.gift);

	const [activeStep, setActiveStep] = React.useState(0);

	const { user, guestSignUpData } = useAppSelector(getFullState);
	const { isLoading, error } = useAppSelector(selectGiftCardFullState);
	const { paymentMethods } = useAppSelector(selectBillingFullState);

	const { paymentProviderAccount } = guestSignUpData as UserSignUpVerificationDto;
	const { paymentSource } = paymentProviderAccount || {};

	useEffect(() => {
		if (giftId && !gift) {
			dispatch(findGiftCard(Number(giftId))).then(r => {
				setGift(r.payload);
			});

			return;
		}
	}, [giftId, gift, dispatch]);

	useEffect(() => {
		if (!!user) {
			dispatch(getPaymentDetails());
		}
	}, [dispatch, user]);

	const validationSchema = useMemo(() => {
		switch (activeStep) {
			case Steps.Payment: {
				return PaymentSchema;
			}
			case Steps.Recipient: {
				return RecipientSchema;
			}
		}
	}, [activeStep]);

	const handleSubmit = (values: typeof initialFormValues) => {
		const action = !!user ? buyGiftCard : buyGiftCardAsGuest;
		const defaultPayload = {
			...values,
			giftCardId: gift.id
		};

		const payload = !!paymentMethods?.id
			? {
					...defaultPayload,
					paymentMethodId: paymentMethods?.id
			  }
			: {
					...defaultPayload,
					elements,
					stripe
			  };

		dispatch(action(payload)).then(r => {
			if (r.error) {
				dispatch(setError(r.payload?.message || r.error?.message));
				setActiveStep(Steps.Error);
			} else {
				let gifterEmail = user?.email;
				let gifterName = getFullName(user);

				if (!gifterEmail) {
					gifterEmail = values.gifterEmail;
					gifterName = values.gifterName;
				}

				const state = {
					gifterEmail,
					gifterName,
					recipientName: values.recipientName,
					recipientEmail: values.recipientEmail,
					totalPrices: gift?.data.price
				};

				history.replace({ pathname: `${routes.shop.gifts.getPath()}/success`, state });
			}
		});
	};

	const handleNext = (values: typeof initialFormValues) => {
		if (activeStep === Steps.Payment) {
			handleSubmit(values);
		} else {
			setActiveStep(s => s + 1);
		}
	};

	if (!gift) {
		return <ContentLoader />;
	}

	return (
		<SignUpLayout>
			<Box className={classes.centerContainer}>
				<Box className={classes.cardsContainer}>
					<Card className={classes.card}>
						<Box>
							<Box px={7} pt={2} className={classes.stepperForm}>
								<Stepper activeStep={activeStep} steps={steps} />
								<Formik
									initialValues={{ ...initialFormValues }}
									validationSchema={validationSchema}
									validateOnChange
									validateOnBlur={false}
									onSubmit={v => handleSubmit(v)}
								>
									{({ values, setFieldValue, setTouched, setFieldTouched, handleChange, isValid }) => (
										<>
											{activeStep === Steps.Error && <GuestSignUpError errorMessage={error} tryAgainEnabled={true} />}
											{activeStep === Steps.Recipient && (
												<Form>
													<Recipient />
												</Form>
											)}
											{activeStep === Steps.Payment && paymentMethods && (
												<PaymentMethods
													showRecurringInfo
													onEdit={() => {
														dispatch(setError(""));
													}}
													showPrefilledInfo={true}
												/>
											)}
											{activeStep === Steps.Payment && !paymentMethods && (
												<PaymentForm
													paymentSource={paymentSource}
													setFieldValue={setFieldValue}
													setTouched={setTouched}
													termValue={undefined}
													showTerms={false}
													setTouchedField={setFieldTouched}
													handleChanged={handleChange}
													startDate={undefined}
													setStartDate={undefined}
													totalAmount={gift?.data.price}
												/>
											)}
											<Box>
												<SimpleFormActions
													loading={isLoading}
													step={activeStep}
													valid={isValid}
													lastStep={1}
													back={() => setActiveStep(s => s - 1)}
													next={() => handleNext(values)}
												/>
											</Box>
										</>
									)}
								</Formik>
							</Box>
						</Box>
					</Card>
					{activeStep !== Steps.Error && <OrderGiftCardSummary gift={gift} />}
				</Box>
			</Box>
		</SignUpLayout>
	);
};

export default OrderGiftCard;
