import React from "react";

import { Box, Typography } from "@material-ui/core";

import { PushWrapper } from "./styles";

import { ISocketNotificationItem, NotificationButtonDataDto } from "../../models";

import Button from "../Button";

const Push = ({
	notification,
	onPrimaryButtonClick,
	onSecondaryButtonClick
}: {
	notification: ISocketNotificationItem;
	onPrimaryButtonClick: (primaryButton: NotificationButtonDataDto, notification: ISocketNotificationItem) => void;
	onSecondaryButtonClick: (primaryButton: NotificationButtonDataDto, notification?: ISocketNotificationItem) => void;
}) => {
	const {
		notification: { title, body, data }
	} = notification;
	const { primaryButton, secondaryButton } = data || {};

	return (
		<PushWrapper>
			<Box mt={2} className={"body"}>
				<Typography>{title}</Typography>
				<Typography>{body}</Typography>
			</Box>
			<Box p={1} display={"flex"} justifyContent={"flex-end"}>
				<Box mr={1}>
					<Button
						variant={"outlined"}
						color={"basic"}
						onClick={() => onSecondaryButtonClick(secondaryButton as NotificationButtonDataDto, notification)}
					>
						{secondaryButton?.text}
					</Button>
				</Box>
				<Button
					variant={"filled"}
					color={"primary"}
					onClick={() => onPrimaryButtonClick(primaryButton as NotificationButtonDataDto, notification)}
				>
					{primaryButton?.text}
				</Button>
			</Box>
		</PushWrapper>
	);
};

export default Push;
