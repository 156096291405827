import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";

import assetsReducer from "./features/Assets/AssetsSlice";
import authReducer from "./features/Auth/authSlice";
import billingReducer from "./features/Billing/BillingSlice";
import bookReducer from "./features/Books/booksSlice";
import courseReducer from "./features/Course/courseSlice";
import fileVaultReducer from "./features/FileVault/fileVault";
import giftCardReducer from "./features/GiftCard/giftCard.slice";
import helpReducer from "./features/Help/helpSlice";
import lessonReducer from "./features/Lesson/lesson.slice";
import liveStreamReducer from "./features/LiveStream/LiveStream.slice";
import myAccountReducer from "./features/MyAccount/myAccountSlice";
import noteReducer from "./features/Note/Notes.slice";
import createTestReducer from "./features/QuestionBank/CreateTest/createTest.slice";
import testReducer from "./features/QuestionBank/Test/test.slice";
import questionBankReducer from "./features/QuestionBank/questionBank.slice";
import searchReducer from "./features/Search/search.slice";
import quizReducer from "./features/Test/test.slice";
import takeTestReducer from "./features/Test/testAttempt.slice";
import themeReducer from "./features/Theme/theme.slice";
import notificationsReducer from "./features/notifications/notifications.slice";

const combinedReducer = combineReducers({
	assets: assetsReducer,
	auth: authReducer,
	billing: billingReducer,
	books: bookReducer,
	courses: courseReducer,
	createTest: createTestReducer,
	fileVault: fileVaultReducer,
	giftCard: giftCardReducer,
	help: helpReducer,
	lesson: lessonReducer,
	liveStream: liveStreamReducer,
	myAccount: myAccountReducer,
	notes: noteReducer,
	notifications: notificationsReducer,
	questionBank: questionBankReducer,
	quiz: quizReducer,
	takeTest: takeTestReducer,
	test: testReducer,
	theme: themeReducer,
	search: searchReducer
});

const rootReducer = (state, action) => {
	// reset redux state on logout
	return action.type === "auth/clearAuth"
		? combinedReducer({ theme: state.theme } as RootState, action)
		: combinedReducer(state, action);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = () =>
	configureStore({
		reducer: rootReducer
	});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
