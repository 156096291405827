import React, { useEffect, useMemo } from "react";

import { BellNotificationsBlock } from "@remar/shared/dist/components/Notifications";
import { NotificationTypeId } from "@remar/shared/dist/models";

import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getUserNotifications, markAsRead } from "store/features/notifications/notifications.slice";

const BellNotifications = () => {
	const dispatch = useAppDispatch();
	const { bellNotifications: bellNotificationsState } = useAppSelector(getFullState);

	useEffect(() => {
		// fetches filtered push notifications for bell/push notifications
		dispatch(
			getUserNotifications({
				filters: {
					"notification.notificationTypeId": [NotificationTypeId.AdminGeneratedPush]
				}
			})
		);
	}, [dispatch]);

	const bellNotifications = useMemo(() => bellNotificationsState?.items || [], [bellNotificationsState]);

	return (
		<BellNotificationsBlock
			markAsRead={() => dispatch(markAsRead({}))}
			bellNotifications={bellNotifications}
			unreadCount={bellNotificationsState.totalUnread}
		/>
	);
};

export default BellNotifications;
