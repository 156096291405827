import React, { ChangeEvent, FC, useState } from "react";

import {
	Box,
	Button,
	Checkbox,
	Chip,
	FilledInputProps,
	FormControl,
	FormControlLabel,
	IconButton,
	LinearProgress,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Switch,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";

import { StylesProvider, ThemeProvider, useTheme } from "@material-ui/core/styles";
import { CameraAlt, Close, CloudUploadRounded, Delete } from "@material-ui/icons";

import { useDispatch } from "react-redux";

import styled from "styled-components";

import { Check, Plus } from "./CustomInput.Icons";
import {
	CustomInputInnerProps,
	CustomInputModelValidations,
	CustomInputProps,
	CustomInputType
} from "./customInput.model";
import { generateClassName, useCustomInputStyles } from "./customInput.styles";
import { validateInput } from "./customInput.utils";

import { ERROR_FIELD_COLOR } from "../../constants";
import { IExtendedTheme } from "../../theme/default";
import { uploadFile } from "../../utils/stateUtils";
import { AutocompleteFilter } from "../AutocompleteFilter";
import { MultipleCheckbox } from "../MultipleCheckbox";
import TinyMCE from "../TinyMCE";
import Uploader from "../Uploader";

export const FileTagContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 16px;
	padding: 0 10px;
`;

const FileTag = ({ name, onClose }) => (
	<FileTagContainer>
		<Box>
			<Typography variant="caption">{name}</Typography>
		</Box>
		<IconButton
			onClick={e => {
				e.stopPropagation();
				e.preventDefault();
				onClose();
			}}
			size="small"
		>
			<Delete />
		</IconButton>
	</FileTagContainer>
);

const textFieldInputTypeProps = {
	[CustomInputType.Email]: { type: "email" },
	[CustomInputType.Multiline]: { multiline: true },
	[CustomInputType.Number]: { type: "number" },
	[CustomInputType.Password]: { type: "password" },
	[CustomInputType.Phone]: { type: "phone" },
	[CustomInputType.Text]: { type: "text" }
};

export const CustomInput: FC<CustomInputProps> = function ({
	inputProps = {} as CustomInputInnerProps,
	InputProps = {} as Partial<FilledInputProps>,
	options,
	containerClassName,
	theme: customTheme,
	...containerProps
}) {
	const boxBorderProps: Record<string, unknown> = {};
	const classes = useCustomInputStyles();
	const dispatch = useDispatch();
	const [progress, setProgress] = useState<number>(0);
	const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
	const [uploading, setUploading] = useState<boolean>(false);
	const { _emit, genericApiService, inputData, plugins, setStateValue, validateForm } = options;
	const {
		defaultValue,
		emptySelectOption,
		error,
		hasClearButton,
		imageExtensionName,
		imageFileType,
		imageUrl,
		isImageFile,
		label,
		pristine,
		readonly,
		selectOptions = [],
		statePath,
		type: inputType,
		uploaderIconName,
		uploaderStateLoaderKey,
		value: inputValue
	} = inputData;
	const defaultTheme = useTheme<IExtendedTheme>();
	const theme = customTheme || defaultTheme;
	const validations: CustomInputModelValidations = { ...(inputData.validations || {}) };
	const validateInputOptions = {
		dispatch,
		setStateValue,
		inputData,
		markAsDirty: true,
		validateForm
	};
	const displayError = error !== null && !pristine;
	const {
		chipsBottomAction,
		fileUploaderDescription,
		onBlur,
		onChange,
		onClose,
		onKeyPress,
		uploaderWidth,
		...otherInputProps
	} = inputProps;
	let boxClassName = "";
	let input;

	const cb = () => {
		setUploading(false);
		setProgress(0);
	};

	switch (inputType) {
		case CustomInputType.Checkbox:
			input = (
				<FormControl size="small" variant="filled" fullWidth>
					<FormControlLabel
						control={
							<Checkbox
								{...otherInputProps}
								disabled={!!readonly}
								color="primary"
								checked={!!inputValue}
								onChange={e => {
									validateInput(e.target.checked, validateInputOptions);
									if (onChange) {
										onChange(e);
									}
								}}
							/>
						}
						label={inputData.label}
						{...(readonly ? { readonly: true } : {})}
					/>
				</FormControl>
			);
			break;
		case CustomInputType.Switch:
			input = (
				<FormControl size="small" variant="filled" fullWidth>
					<FormControlLabel
						control={
							<Switch
								{...otherInputProps}
								disabled={!!readonly}
								checked={!!inputValue}
								onChange={e => {
									validateInput(e.target.checked, validateInputOptions);
									if (onChange) {
										onChange(e);
									}
								}}
								name="isVisible"
								inputProps={{ "aria-label": "primary checkbox" }}
								classes={{
									switchBase: classes.switchBase,
									track: classes.track,
									checked: classes.checked
								}}
							/>
						}
						label={inputData.label}
						{...(readonly ? { readonly: true } : {})}
					/>
				</FormControl>
			);
			break;
		case CustomInputType.CheckboxArray:
			input = (
				<MultipleCheckbox
					className={containerClassName}
					selectOptions={selectOptions}
					otherInputProps={otherInputProps}
					readonly={readonly}
					values={inputValue as number[]}
					onChange={value => validateInput(value, validateInputOptions)}
				/>
			);
			break;
		case CustomInputType.Chips:
			const namedOptionsMap: { [value: string]: string } = {};
			const selectedOptionsMap: { [value: string]: boolean } = {};
			if (inputValue instanceof Array && inputValue.length) {
				inputValue.forEach(item => (selectedOptionsMap[item] = true));
			}
			const MenuItems = (emptySelectOption ? [emptySelectOption] : []).concat(selectOptions).map(option => {
				const value = option.value as string;
				namedOptionsMap[value] = option.text;
				return (
					<MenuItem value={value as string} key={`${value}`}>
						<Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
							<Box>
								<Box mb={-1}>
									<Typography>{option.text}</Typography>
								</Box>
								{option.subtitle && <Typography variant="caption">{option.subtitle}</Typography>}
							</Box>
							<Box className={classes.optionCheckbox}>
								{selectedOptionsMap[value] ? <Check fontSize="small" /> : <Plus fontSize="small" />}
							</Box>
						</Box>
					</MenuItem>
				);
			});
			input = (
				<FormControl size="small" variant="filled" fullWidth hiddenLabel>
					<Select
						value={inputValue}
						multiple
						disableUnderline
						displayEmpty
						fullWidth
						open={isSelectOpen}
						disabled={selectOptions.length === 0 || readonly}
						onOpen={() => {
							setIsSelectOpen(true);
						}}
						onChange={e => {
							const value = (e.target.value as unknown[]).filter(v => v);
							validateInput(value, {
								...validateInputOptions,
								inputData: { ...inputData, validations }
							});
							if (onChange) {
								onChange(e as React.ChangeEvent<HTMLInputElement>);
							}
						}}
						onClose={() => {
							setIsSelectOpen(false);
							if (onClose) {
								onClose(inputValue);
							}
						}}
						renderValue={selected => {
							if ((selected as string[]).length === 0) {
								return <div>{label || inputData.placeholder}</div>;
							} else {
								return (
									<div className={classes.chips}>
										{selected ? (
											(selected as string[]).map(value => (
												<Chip
													color="primary"
													size="small"
													key={value}
													label={namedOptionsMap[value]}
													className={classes.chip}
												/>
											))
										) : (
											<></>
										)}
									</div>
								);
							}
						}}
					>
						{MenuItems}
						{chipsBottomAction && (
							<MenuItem
								onClick={e => {
									setIsSelectOpen(false);
									e.preventDefault();
									chipsBottomAction();
								}}
							>
								<Box py={1} textAlign="center" width="100%">
									<Typography className={classes.bottomLabel}>{inputData.chipsBottomLabel}</Typography>
								</Box>
							</MenuItem>
						)}
					</Select>
				</FormControl>
			);
			break;
		case CustomInputType.File:
			boxClassName += classes.uploader;
			const imageExtensionArray = (imageExtensionName || "png").split(",").map(i => `.${i.trim()}`);
			input = (
				<>
					{typeof inputValue === "string" && inputValue.length ? (
						isImageFile ? (
							<Box className={classes.cancelContainer}>
								<IconButton
									size="small"
									className={classes.cancelThumbnail}
									onClick={() => {
										validateInput("", validateInputOptions);
										dispatch(setStateValue({ key: `${statePath}.imageUrl`, value: "" }));
										dispatch(setStateValue({ key: `${statePath}.name`, value: "" }));
										if (onChange) {
											onChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
										}
									}}
								>
									<Close className={classes.cancelIcon} />
								</IconButton>

								<Box className={`${classes.imgContainer}  ${imageUrl ? classes.imgContainerBorder : ""}`}>
									<img alt="uploaded image" src={imageUrl} className={classes.image} />
								</Box>
							</Box>
						) : (
							<FileTag
								onClose={() => {
									validateInput("", validateInputOptions);
									dispatch(setStateValue({ key: `${statePath}.imageUrl`, value: "" }));
									dispatch(setStateValue({ key: `${statePath}.name`, value: "" }));
									if (onChange) {
										onChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
									}
								}}
								name={(inputData as unknown as { name: string }).name}
							/>
						)
					) : (
						<Box display="flex" flexDirection="column">
							{isImageFile ? (
								<Uploader
									accept={imageExtensionArray}
									boxProps={{ width: uploaderWidth || "246px", minHeight: "246px" }}
									clearFileInputSubject={undefined}
									error={false}
									header={label || "Upload File"}
									icon={
										uploaderIconName === "cloud-upload" ? (
											<CloudUploadRounded
												name="cloud-upload"
												style={{ width: "50px", height: "50px", fill: "hsl(223,10%,58%)" }}
											/>
										) : (
											<CameraAlt style={{ width: "50px", height: "50px", fill: "hsl(223,10%,58%)" }} />
										)
									}
									loading={false}
									onAdd={e => {
										if (e && e[0]) {
											setUploading(true);
											dispatch(
												uploadFile!(
													{ file: e[0] },
													{
														_emit,
														genericApiService,
														imageUrlStatePath: isImageFile ? `${statePath}.imageUrl` : undefined,
														loaderStatePath: uploaderStateLoaderKey,
														s3KeyStatePath: `${statePath}.value`,
														setStateValue,
														uploadCb: cb,
														onProgress: ({ loaded, total }) => {
															setProgress((loaded / total) * 100);
														}
													}
												)
											);
											if (onChange) {
												onChange({ target: { value: inputValue } } as ChangeEvent<HTMLInputElement>);
											}
										} else {
											validateInput(null, validateInputOptions);
											if (onChange) {
												onChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
											}
										}
									}}
									progress={progress}
									title={
										fileUploaderDescription || (
											<>
												Drag and Drop File Here or Browse to Choose a File <br />
											</>
										)
									}
								/>
							) : (
								<Button color="primary" variant="contained" component="label">
									{label || "Upload Media"}
									<input
										hidden
										accept={imageFileType || "image/*"}
										type="file"
										onChange={event => {
											const e = event.target.files;
											if (e && e[0]) {
												setUploading(true);
												dispatch(
													uploadFile(
														{ file: e[0] },
														{
															_emit,
															genericApiService,
															loaderStatePath: uploaderStateLoaderKey,
															s3KeyStatePath: `${statePath}.value`,
															fileNameStatePath: `${statePath}.name`,
															setValue: value => validateInput(value, validateInputOptions),
															setStateValue,
															uploadCb: cb,
															onProgress: ({ loaded, total }) => {
																setProgress((loaded / total) * 100);
															}
														}
													)
												);
												if (onChange) {
													onChange({
														target: { value: inputValue }
													} as ChangeEvent<HTMLInputElement>);
												}
											} else {
												validateInput(null, validateInputOptions);
												if (onChange) {
													onChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
												}
											}
										}}
									/>
								</Button>
							)}
							{uploading && (
								<Box
									width={uploaderWidth || 246}
									mt="3px"
									justifyContent="space-between"
									display="flex"
									alignItems="center"
								>
									<LinearProgress
										style={{ background: "hsl(0,0%,10%)", width: "100%" }}
										color="primary"
										variant={progress ? "determinate" : "indeterminate"}
										value={progress}
									/>
									<Box color="#fff">{Math.floor(progress)}%</Box>
								</Box>
							)}
						</Box>
					)}
				</>
			);
			break;
		case CustomInputType.Radio:
			input = readonly ? (
				<Typography>{(selectOptions.find(item => item.value === inputValue) || {}).text}</Typography>
			) : (
				<RadioGroup
					row
					value={`${inputValue}`}
					style={{ color: "primary" }}
					onChange={e => {
						validateInput(e.target.value, {
							...validateInputOptions,
							inputData: { ...inputData, validations }
						});
						if (onChange) {
							onChange(e as React.ChangeEvent<HTMLInputElement>);
						}
					}}
				>
					{selectOptions.map(item => (
						<FormControlLabel
							disabled={item.disabled}
							key={`${item.value}`}
							value={`${item.value}`}
							control={<Radio color="primary" />}
							label={<Typography className={item.disabled ? classes.radioDisabled : ""}>{item.text}</Typography>}
						/>
					))}
				</RadioGroup>
			);
			break;
		case CustomInputType.Select:
			input = (
				<FormControl size="small" variant="filled" fullWidth hiddenLabel>
					<Select
						classes={{ root: classes.selectRoot }}
						value={inputValue}
						disableUnderline
						disabled={selectOptions.length === 0 || readonly}
						displayEmpty
						onChange={e => {
							validateInput(e.target.value, {
								...validateInputOptions,
								inputData: { ...inputData, validations }
							});
							if (onChange) {
								onChange(e as React.ChangeEvent<HTMLInputElement>);
							}
						}}
					>
						<MenuItem value="" disabled>
							{label || inputData.placeholder}
						</MenuItem>
						{(emptySelectOption ? [emptySelectOption] : []).concat(selectOptions).map(option => (
							<MenuItem
								value={option.value as string}
								{...(option.disabled && { disabled: true })}
								key={`${option.value}`}
							>
								{option.text}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
			break;
		case CustomInputType.Editor:
			input = (
				<TinyMCE
					value={inputValue as string}
					{...(plugins && { plugins })}
					onChange={text => {
						validateInput(text, {
							...validateInputOptions,
							inputData: { ...inputData, validations }
						});
					}}
				/>
			);
			break;
		case CustomInputType.AutoComplete: {
			input = (
				<AutocompleteFilter
					options={selectOptions as unknown as { id: number; name: string }[]}
					value={inputValue as unknown as { id: number; name: string }[]}
					setValue={v => {
						validateInput(v, {
							...validateInputOptions,
							inputData: { ...inputData, validations }
						});
						onChange && onChange(v as unknown as React.ChangeEvent<HTMLInputElement>);
					}}
					filterName={label || input.name}
				/>
			);
			break;
		}
		default:
			const inputTypeSpecificProps = textFieldInputTypeProps[inputType];
			// boxBorderProps.border = 1;
			// boxBorderProps.borderColor = displayError ? ERROR_FIELD_COLOR : "";
			if (inputType === CustomInputType.Number) {
				validations.isNumber = true;
			}
			input = (
				<TextField
					{...otherInputProps}
					{...inputTypeSpecificProps}
					InputProps={{
						style: { color: theme.palette.primary.main },
						disableUnderline: true,
						...InputProps
					}}
					InputLabelProps={{
						style: { color: theme.palette.primary.main }
					}}
					value={inputValue}
					{...(inputType === CustomInputType.Number && {
						onWheel: e => (e.target as HTMLElement).blur()
					})}
					onChange={e => {
						validateInput(e.target.value, {
							...validateInputOptions,
							inputData: { ...inputData, validations }
						});
						if (onChange) {
							onChange(e);
						}
					}}
					onKeyDown={e => {
						if (onKeyPress) {
							onKeyPress(e);
						}
					}}
					onBlur={e => {
						validateInput(e.target.value.trim(), {
							...validateInputOptions,
							inputData: { ...inputData, validations }
						});
						if (onBlur) {
							onBlur(e);
						}
					}}
					color="primary"
					error={displayError}
					fullWidth
					variant="filled"
					size="small"
					label={inputData.label}
					placeholder={inputData.placeholder}
					{...(readonly ? { readonly: true } : {})}
				/>
			);
			break;
	}

	if (displayError) {
		boxBorderProps.border = 1;
		boxBorderProps.borderColor = ERROR_FIELD_COLOR;
		boxClassName += classes.topLevelContainerWithError;
	}

	return (
		<StylesProvider generateClassName={generateClassName}>
			<ThemeProvider theme={theme}>
				<Box {...containerProps}>
					<Box {...boxBorderProps} className={boxClassName} display="flex" flexDirection="row">
						{input}
						{hasClearButton && (
							<Box ml={2}>
								<Tooltip title="Clear selection">
									<IconButton
										disabled={!selectOptions.length || readonly}
										onClick={() => {
											if (readonly) {
												dispatch(
													setStateValue({
														key: `${statePath}.readonly`,
														value: false
													})
												);
											}
											validateInput(defaultValue, validateInputOptions);
										}}
									>
										<Close />
									</IconButton>
								</Tooltip>
							</Box>
						)}
					</Box>
					{displayError && (
						<Typography
							className={`CustomInputError ${classes.errorMessage}`}
							style={containerProps.width ? { width: containerProps.width as string } : {}}
						>
							{error}
						</Typography>
					)}
				</Box>
			</ThemeProvider>
		</StylesProvider>
	);
};
