import React, { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import { GiftDto } from "@remar/shared/dist/models";
import { Integrations } from "@remar/shared/dist/models/package.model";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { add, format } from "date-fns";
import { useAppSelector } from "store";
import { getFullState } from "store/features/Auth/authSlice";
import { VerifyGiftCardCodeResponse } from "store/services";

import {
	BoldText,
	BookLabel,
	BooksWrapper,
	CourseNameWrapper,
	DescriptionText,
	Disclaimer,
	SubTotalWrapper,
	TotalWrapper,
	useStyles
} from "../components/styles";

const titleStyle = { fontSize: "22px", fontWeight: 700 };

type GiftSignUpSummaryProps = {
	gift?: VerifyGiftCardCodeResponse;
};
const GiftSignUpSummary = ({ gift }: GiftSignUpSummaryProps) => {
	const classes = useStyles();

	const { isLoading } = useAppSelector(getFullState);

	const { gifterName, giftCard } = gift || {};

	const invitedBy = useMemo(() => {
		const [firstName, lastName = ""] = gifterName.split(" ");
		return { firstName, lastName };
	}, [gifterName]);

	if (!gift) return null;

	const { userSubscriptionType, books } = giftCard as GiftDto;

	const { days = 0, months = 0 } = userSubscriptionType?.duration ?? {};
	const renewalDate = format(add(new Date(), { days, months }), "dd, MMM yyyy");
	const courseName = userSubscriptionType.allowedCourses?.[0].name;
	const price = userSubscriptionType?.subTypeEIDItems?.find(
		i => i.integrationId === Integrations.StripeIntegrationId && i.parentId === null
	)?.data.price;

	return (
		<Box display="flex" flexDirection="column">
			<Box className={classes.summaryRoot} pb={0} style={{ position: "relative" }}>
				<Box>
					<Box mb={4}>
						<Typography style={titleStyle}>Invited by</Typography>
						<Box display="flex" className={classes.invitedBy} p={1} mt={2}>
							<Box borderRadius={5} overflow="hidden" width={40} height={40} mr={1}>
								{!isLoading && <Avatar fullName={gifterName} variant="rounded" />}
							</Box>
							<Box display="flex" flexDirection={"column"}>
								<Typography
									style={{ fontSize: "15px", fontWeight: 700 }}
								>{`${invitedBy?.firstName} ${invitedBy?.lastName}`}</Typography>
							</Box>
						</Box>
					</Box>

					<Box mb={2}>
						<Typography style={titleStyle}>Order Summary</Typography>
					</Box>
					<CourseNameWrapper $spaceBetween p={1} mt={2}>
						<Box display="flex" flexDirection={"column"}>
							<DescriptionText>Course Name</DescriptionText>
							<BoldText>{courseName}</BoldText>
						</Box>
						<Box>
							<BoldText>{formatUSD(price)}</BoldText>
						</Box>
					</CourseNameWrapper>

					<BooksWrapper>
						{books?.map((b, i) => (
							<CourseNameWrapper key={i} p={1} mt={2}>
								{b.imageUrl && (
									<Box mr={2}>
										<img width="50px" src={b.imageUrl} alt="book name" />
									</Box>
								)}
								<Box display="flex" flexDirection={"column"} alignItems={"flex-start"}>
									<BoldText>{b.data?.name}</BoldText>
									<Box display="flex" alignItems="center">
										<BookLabel key={i} $isPhysical={b.integrationId !== Integrations.DigitalAssetIntegrationId}>
											{false ? "Physical Book" : "Digital book"}
										</BookLabel>
									</Box>
								</Box>
							</CourseNameWrapper>
						))}
					</BooksWrapper>

					<SubTotalWrapper mt={3}>
						<Box className={"subTotal"} mb={0.5}>
							<p>Subtotal</p>
							<p>{formatUSD(price)}</p>
						</Box>
						<Box display="flex" justifyContent="space-between">
							<p>Invited student discount</p>
							<p>-{formatUSD(price)}</p>
						</Box>
					</SubTotalWrapper>

					<TotalWrapper mt={3}>
						<p>Your Total</p>
						<p>{formatUSD(0)}</p>
					</TotalWrapper>

					<Disclaimer mt={2}>
						<p>
							Course fee is covered by gifter until renewal.
							<br /> Renewal of course starts on {renewalDate}.
						</p>
					</Disclaimer>
				</Box>
			</Box>
		</Box>
	);
};

export default GiftSignUpSummary;
