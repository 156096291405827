import React from "react";

import { Box } from "@material-ui/core";

import { Jpeg, MOV, MP4, Pdf, Ppt } from "./icons";
import { FileUploadedSize, FileUploadedText, MediaUploadHeading, useFilesUploadedStyles } from "./styles";

import { File } from "../../models";
import { convertDuration } from "../../utils/convertMinsToHrsMins";

const computeBytes = x => {
	const units = ["bytes", "KB", "MB", "GB"];
	let l = 0;
	let n = parseInt(x, 10) || 0;
	while (n >= 1024 && ++l) {
		n = n / 1024;
	}
	return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};

const getIcon = type => {
	const iconType = type.split("/")[1];
	switch (iconType) {
		case "jpeg":
			return <Jpeg />;
		case "jpg":
			return <Jpeg />;
		case "pdf":
			return <Pdf />;
		case "vnd.ms-powerpoint":
			return <Ppt />;
		case "vnd.openxmlformats-officedocument.presentationml.presentation":
			return <Ppt />;
		case "mp4":
			return <MP4 />;
		case "mov":
			return <MOV />;
		default:
			return;
	}
};

interface Props {
	onClick?: (_) => void;
	attachments: Array<File & { totalLengthInSeconds?: number; thumbnailUrl?: string }>;
	IconSvg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	showTitle?: boolean;
	borderColor?: string;
	filenameColor?: string;
	isDownload?: boolean;
}
export const FilesUploaded = ({
	onClick,
	attachments,
	IconSvg,
	showTitle = true,
	borderColor = "hsl(225,34%,20%)",
	filenameColor,
	isDownload = false
}: Props) => {
	const classes = useFilesUploadedStyles({ borderColor, isDownload });
	return (
		<>
			<MediaUploadHeading>{showTitle && "FILES UPLOADED"}</MediaUploadHeading>
			{attachments.length !== 0 &&
				attachments.map(attachment => (
					<Box
						className={classes.fileUploaded}
						key={attachment.id}
						{...(isDownload && { onClick: () => onClick && onClick(attachment) })}
					>
						<Box className="container">
							<Box>
								{attachment.fileType ? (
									getIcon(attachment.fileType)
								) : (
									<img alt={attachment.name} src={attachment.thumbnailUrl} />
								)}
							</Box>
							<Box className={classes.subContainer}>
								<Box ml={1}>
									<FileUploadedText color={filenameColor}>{attachment.name}</FileUploadedText>
									<FileUploadedSize>
										{attachment.fileSizeInBytes
											? computeBytes(attachment.fileSizeInBytes)
											: convertDuration(attachment?.totalLengthInSeconds)}
									</FileUploadedSize>
								</Box>
							</Box>
							<Box>
								{IconSvg && (
									<IconSvg
										className={classes.icon}
										{...(!isDownload && { onClick: () => onClick && onClick(attachment) })}
									/>
								)}
							</Box>
						</Box>
					</Box>
				))}
		</>
	);
};
