import React, { useState } from "react";

import { Box, Card, Grid, TextField, Typography } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { format } from "date-fns";

export enum DataTypes {
	Chat = "chat"
}

export type ChatMessage = {
	dataType: DataTypes;
	timeStamp: string;
	message: string;
	sendBy: string;
};

export enum WebRTCAdaptorStatus {
	INITIALIZED = "initialized",
	STATE_CHANGED = "ice_connection_state_changed",
	PUBLISH_STARTED = "publish_started",
	PUBLISH_FINISHED = "publish_finished",
	CLOSED = "closed",
	RECONNECTING = "reconnection_attempt_for_publisher",
	UPDATED_STATS = "updated_stats",
	NUMBER_VIEWERS = "number_of_viewers",
	PLAY_STARTED = "play_started",
	PLAY_FINISHED = "play_finished",
	STREAM_INFO = "stream_information",
	DATA_CHANNEL_OPENED = "data_channel_open",
	DATA_RECEIVED = "data_received",
	DATA_CHANNEL_ERROR = "data_channel_error",
	DATA_CHANNEL_CLOSED = "data_channel_closed"
}

const ChatComponent = ({ handleSendWebRtcData, chatMessages, user: { firstName = "", lastName = "" } }) => {
	const [message, setMessage] = useState("");

	const handleMessageSend = async () => {
		const m = {
			dataType: DataTypes.Chat,
			timeStamp: new Date().toISOString(),
			message,
			sendBy: `${firstName} ${lastName}`
		} as ChatMessage;
		handleSendWebRtcData(m);
		setMessage("");
	};

	if (!chatMessages) {
		return null;
	}

	return (
		<Grid item xs={12}>
			<Card>
				<Typography variant="h6">Chat:</Typography>
				<Box mb={2} p={2} style={{ maxHeight: 600, overflowY: "auto", borderRadius: 4 }}>
					{chatMessages?.map(({ message, sendBy, timeStamp }) => (
						<Box key={timeStamp} pb={1}>
							[{format(new Date(timeStamp), "hh:mm aa")}] {sendBy}: {message}
						</Box>
					))}
				</Box>
				<TextField
					variant="outlined"
					fullWidth
					value={message}
					onChange={e => setMessage(e.target.value)}
					placeholder="Type a message"
					onKeyDown={async e => {
						if (e.key === "Enter") {
							handleMessageSend();
						}
					}}
					InputProps={{
						endAdornment: (
							<Box ml={2}>
								<Send style={{ cursor: "pointer" }} onClick={handleMessageSend} />
							</Box>
						)
					}}
				/>
			</Card>
		</Grid>
	);
};

export default ChatComponent;
