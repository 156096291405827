import { millisecondsToHours } from "date-fns";

const convertMinsToHrsMins = (mins: number): string => {
	const h = Math.floor(mins / 60);
	const m = Math.floor(mins % 60);
	const s = Math.floor(((mins % 60) - m) * 60);
	const hour = h < 10 ? "0" + h : h;
	const min = m < 10 ? "0" + m : m;
	return `${hour}h ${!!min ? `${min}min` : ""} ${s ? `${s}sec` : ""}`;
};

export const convertMillisecondsToHours = (ms: number): number => {
	return millisecondsToHours(ms);
};

export const convertMillisecondsToMinutes = (ms: number): number => {
	return Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
};

const getHoursLabel = (hours: number): string => {
	return getLabel(hours, "hr");
};

const getMinutesLabel = (minutes: number): string => {
	return getLabel(minutes, "min");
};

const getLabel = (value: number, label: string): string => {
	return value === 1 ? label : `${label}s`;
};

export const convertDuration = (durationsInSeconds = 0): string => {
	const hours = Math.floor(durationsInSeconds / 3600);
	const minutes = Math.floor((durationsInSeconds % 3600) / 60);

	let formattedDuration = "";
	if (hours > 0) formattedDuration += `${hours} ${getHoursLabel(hours)} `;
	if (minutes >= 1 && hours === 0) formattedDuration += `${minutes} ${getMinutesLabel(minutes)}`;

	if (hours === 0 && minutes < 1) {
		formattedDuration += `${durationsInSeconds} ${getLabel(durationsInSeconds, "second")}`;
	}

	return formattedDuration.trim();
};

export default convertMinsToHrsMins;
