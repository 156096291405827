import { Box } from "@material-ui/core";

import { ReactComponent as IconEye } from "@remar/shared/dist/assets/icons/icon-eye.svg";
import styled from "styled-components";

export const VideoContainer = styled(Box)`
	width: 100%; /* Parent div takes full width */
	position: relative;
	.video-js {
		width: 100% !important; /* Video takes full width of parent */
		height: 500px !important; /* Set your desired height */
	}
	.loader {
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const StreamsWrapper = styled(Box)`
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;
	grid-gap: 15px;
	.stream {
		display: flex;
		flex-direction: column;
		width: 370px;
		padding: 0;
		cursor: pointer;
		img {
			width: inherit;
			height: 300px;
		}
		.stream_content {
			display: flex;
			flex-direction: column;
			padding: 15px;
			.title {
				font-family: var(--poppins);
				font-size: 18px;
				font-weight: 700;
			}
			.description {
				font-family: var(--openSans);
				font-size: 14px;
				font-weight: 400;
				margin: 10px 0;
			}
			.stats {
				display: flex;
				grid-gap: 25px;
				.stats_item {
					display: flex;
					align-items: center;
					grid-gap: 5px;
					p {
						font-family: var(--openSans);
						font-size: 14px;
					}
					svg {
						width: 14px;
						path:last-child {
							fill: #fff;
						}
					}
				}
			}
		}
	}
`;

export const StyledEye = styled(IconEye)`
	width: 22px;
	g {
		path:last-child {
			fill: ${props => props.theme.palette.colors.basic[400]};
		}
	}
`;
