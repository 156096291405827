import React from "react";

export const Jpeg = () => (
	<svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path d="M1 0h24v24H1z" />
			<path
				d="M22 6h-5.25a.75.75 0 0 1-.75-.75V0H5.125C4.505-.002 4.002.5 4 1.12v21.755c-.002.62.5 1.123 1.12 1.125h15.755c.62.002 1.123-.5 1.125-1.12V6z"
				fill="#EDF1F7"
			/>
			<path
				d="M21.672 4.922 17.082.328A1.125 1.125 0 0 0 16.287 0H16v5.25c0 .414.336.75.75.75H22v-.286a1.12 1.12 0 0 0-.328-.792z"
				fill="#F7F9FC"
			/>
			<rect width="14" height="7.2" rx=".5" transform="translate(.6 2.4)" fill="#0091FF" />
			<text
				fontFamily="OpenSans-SemiBold, Open Sans"
				fontSize="5"
				fontWeight="500"
				letterSpacing="-.1"
				fill="#FFF"
				transform="translate(.6)"
			>
				<tspan x="1.4" y="8">
					JPEG
				</tspan>
			</text>
			<g fill="#C5CEE0">
				<path d="M8.667 12h7.666c.917 0 1.667.707 1.667 1.569v4.862c0 .862-.75 1.569-1.667 1.569H8.667C7.75 20 7 19.293 7 18.431V13.57C7 12.707 7.75 12 8.667 12zm6.191 1.151c.472 0 .853.36.853.803 0 .444-.381.804-.853.804-.471 0-.854-.36-.854-.804 0-.444.383-.803.854-.803zm-5.497 4.392 1.958-2.992c.164-.178.536-.178.7 0l1.271 1.941.504-.77c.117-.127.38-.127.497 0l1.391 2.125a.568.568 0 0 1-.48.894h-5.168c-.661 0-1.044-.633-.673-1.198z" />
			</g>
		</g>
	</svg>
);

export const Pdf = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h24v24H0z" />
			<path
				d="M21 6h-5.25a.75.75 0 0 1-.75-.75V0H4.125C3.505-.002 3.002.5 3 1.12v21.755c-.002.62.5 1.123 1.12 1.125h15.755c.62.002 1.123-.5 1.125-1.12V6z"
				fill="#EDF1F7"
			/>
			<path
				d="M17.285 18.047c-.315-.362-.774-.612-1.293-.706-.821-.149-1.6-.203-2.333-.19-.667-.678-1.311-1.479-1.751-2.359.385-1.279.588-2.385.703-3.008.059-.32-.03-.661-.236-.912a1.006 1.006 0 0 0-.775-.372h-.001c-.29 0-.535.12-.71.344-.588.755-.187 2.58-.1 2.94.089.363.215.718.37 1.061-.305.97-.716 2.022-1.273 3.018-1.86.683-2.967 1.622-3.058 1.701-.308.303-.46.606-.453.903.005.168.065.417.325.645.199.175.454.263.713.263.262 0 .53-.09.746-.27.897-.744 1.616-1.668 2.193-2.648.864-.297 1.886-.531 3.037-.587a17.129 17.129 0 0 0 2.17 1.782c.55.38 1.284.331 1.747-.116.433-.419.425-.976-.021-1.489zm-9.49 2.46c-.174.148-.43.158-.58.023-.059-.053-.089-.1-.09-.143 0-.034.015-.166.236-.388.016-.014.744-.642 2.014-1.249a9.15 9.15 0 0 1-1.58 1.758zm3.642-9.178c.042-.049.087-.079.185-.079.14 0 .23.078.272.125a.41.41 0 0 1 .099.35c-.096.481-.249 1.244-.5 2.15a8.893 8.893 0 0 1-.021-.079c-.209-.795-.357-2.086-.035-2.467zm-.562 6.296c.3-.627.551-1.261.761-1.875.326.546.71 1.055 1.114 1.518-.676.069-1.302.196-1.875.357zm5.89 1.302c-.234.244-.616.265-.907.05-.399-.297-.983-.76-1.608-1.352.488.025.995.084 1.518.185.38.074.711.265.932.537.285.352.158.484.065.58z"
				fill="#E56353"
				fillRule="nonzero"
			/>
			<path
				d="M20.672 4.922 16.082.328A1.125 1.125 0 0 0 15.287 0H15v5.25c0 .414.336.75.75.75H21v-.286a1.12 1.12 0 0 0-.328-.792z"
				fill="#F7F9FC"
			/>
			<g transform="translate(.6 2.4)">
				<rect fill="#1998D5" width="12" height="7.2" rx=".5" />
				<path
					d="M2.43 2.1c.512 0 .884.098 1.118.295.235.196.352.499.352.907 0 .41-.12.708-.359.898-.24.19-.606.285-1.1.285h-.598v.99h-.568V2.1h1.156zm3.363 0c.597 0 1.056.147 1.376.442.321.294.481.704.481 1.229 0 .524-.155.94-.467 1.245-.31.306-.787.459-1.428.459H4.65V2.1h1.143zm4.857 0-.005.531H8.97v.927h1.496v.526H8.97v1.391H8.4V2.1h2.25zm-4.876.526h-.562V4.94h.624c.403 0 .712-.097.926-.292.214-.195.321-.482.321-.862 0-.772-.436-1.159-1.31-1.159zm-3.353-.01h-.578V3.97h.66c.315 0 .53-.06.643-.181.114-.121.171-.298.171-.532 0-.233-.072-.398-.217-.495-.144-.096-.37-.144-.679-.144z"
					fill="#FFF"
				/>
			</g>
		</g>
	</svg>
);

export const Ppt = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<g>
				<path d="M0 0h24v24H0z" />
				<path
					d="M21 6h-5.25a.75.75 0 0 1-.75-.75V0H4.125C3.505-.002 3.002.5 3 1.12v21.755c-.002.62.5 1.123 1.12 1.125h15.755c.62.002 1.123-.5 1.125-1.12V6z"
					fill="#EDF1F7"
				/>
				<path
					d="M20.672 4.922 16.082.328A1.125 1.125 0 0 0 15.287 0H15v5.25c0 .414.336.75.75.75H21v-.286a1.12 1.12 0 0 0-.328-.792z"
					fill="#F7F9FC"
				/>
				<rect width="12" height="7.2" rx=".5" transform="translate(.6 2.4)" fill="#FA8B6F" />
				<rect fill="#C5CEE0" x="7" y="12" width="10" height="9" rx="1.5" />
				<rect fill="#FFF" x="10" y="14" width="4" height="1" rx=".5" />
				<rect fill="#FFF" x="10" y="16" width="4" height="1" rx=".5" />
				<rect fill="#FFF" x="10" y="18" width="4" height="1" rx=".5" />
			</g>
			<text fontFamily="OpenSans-SemiBold, Open Sans" fontSize="5" fontWeight="500" letterSpacing=".2" fill="#FFF">
				<tspan x="2" y="8">
					PPT
				</tspan>
			</text>
		</g>
	</svg>
);

export const MP4 = () => (
	<svg width="24" height="26" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="#D3D6E0"
			transform="translate(3 0.00146484)"
			d="M18 6L12.750004 6L12.750004 6C12.33579 6 12 5.6642175 12 5.2500038C12 5.2500038 12 5.2500038 12 5.2500038L12 0L1.1250036 0L1.1250036 0C0.50523645 -0.0015516508 0.0015598779 0.49960673 0 1.1193693C0 1.1193715 0 1.1193739 0 1.1193763L0 22.875002L0 22.874996C-0.0015541983 23.494764 0.49960205 23.998446 1.1193645 24C1.1193669 24 1.1193693 24 1.1193717 24L16.874996 24L16.874996 24C17.494764 24.001556 17.99844 23.500399 18 22.880636C18 22.880632 18 22.88063 18 22.880629L18 6L18 6Z"
			fillRule="evenodd"
		/>
		<path
			fill="#ECEFF4"
			transform="translate(15 0.00146508)"
			d="M5.671875 4.9218783L1.0828124 0.32812837L1.0828125 0.3281284C0.8712703 0.11731745 0.58458751 -0.00072880165 0.28593749 0L0 0L0 5.2500033L0 5.2500033C0 5.6642175 0.33578673 6 0.75 6L6 6L6 5.714066L6 5.7140522C6 5.4169254 5.8819666 5.1319675 5.671865 4.9218693L5.671875 4.9218783Z"
			fillRule="evenodd"
		/>
		<path
			fill="#898F9E"
			transform="translate(7.875 13.0515)"
			d="M6.4168892 4.7028809L6.4168892 6.5478516L7.6724796 7.4324708C7.9159775 7.6035643 8.25 7.4291015 8.25 7.1302733L8.25 4.1194334C8.2502861 3.8217773 7.9169803 3.6459961 7.6727657 3.8172362L6.4168892 4.7028809ZM0.22917469 4.21875C0.10260498 4.21875 7.0122809e-08 4.3236833 7.0122809e-08 4.453125L7.0122809e-08 4.921875C7.0122809e-08 5.0513167 0.10260498 5.15625 0.22917467 5.15625L0.91669852 5.15625L0.91669852 6.7998047C0.91669852 7.1863317 1.2231207 7.4998388 1.6012144 7.5L5.2737374 7.5C5.6518312 7.4999189 5.9583178 7.1864781 5.9583964 6.7998047L5.9583964 4.9189453C5.9583964 4.5324183 5.6519742 4.2189112 5.273881 4.21875L0.22917469 4.21875ZM3.4376192 0.98481447C3.1269443 0.40092772 2.5290852 0 1.8333969 0C0.82084042 0 0 0.83946681 0 1.875C0 2.9105332 0.82084042 3.75 1.8333969 3.75L5.0418415 3.75C6.0543976 3.75 6.8752379 2.9105346 6.8752379 1.875C6.8752379 0.83946681 6.054399 0 5.041841 0C4.3461533 0 3.7482941 0.40092772 3.4376192 0.98481447ZM0.91669846 1.875C0.91669846 1.3572334 1.3271186 0.9375 1.8333969 0.9375C2.3396752 0.9375 2.7500954 1.3572334 2.7500954 1.875C2.7495427 2.3925321 2.3394458 2.8119345 1.8333969 2.8125C1.3271186 2.8125 0.91669846 2.3927665 0.91669846 1.875ZM4.1251431 1.875C4.1251431 1.3572334 4.535563 0.9375 5.0418415 0.9375C5.5481195 0.9375 5.95854 1.3572334 5.95854 1.875C5.9579873 2.3925321 5.5478907 2.8119345 5.0418415 2.8125C4.535563 2.8125 4.1251431 2.3927665 4.1251431 1.875Z"
			fillRule="evenodd"
		/>
		<path
			fill="#ED5465"
			transform="translate(0.6 2.40147)"
			d="M0.60000002 0L11.4 0C11.731371 -6.0871837e-17 12 0.26862916 12 0.60000002L12 6.5999999C12 6.9313707 11.731371 7.1999998 11.4 7.1999998L0.60000002 7.1999998C0.26862916 7.1999998 3.796667e-17 6.9313707 -4.4408921e-16 6.5999999L-1.110223e-16 0.60000002C-1.5160353e-16 0.26862916 0.26862916 2.6262582e-16 0.60000002 2.220446e-16L0.60000002 0Z"
			fillRule="evenodd"
		/>
		<path
			fill="white"
			transform="translate(1.8975 4.35147)"
			d="M0.53625 3.3783751L0.53625 0.85799998L1.389375 2.5301249L1.925625 2.5301249L2.8080001 0.84824997L2.8080001 3.3783751L3.34425 3.3783751L3.34425 0L2.7105 0L1.672125 2.013375L0.65324998 0L0 0L0 3.3783751L0.53625 3.3783751ZM4.723875 3.3783751L4.723875 2.1059999L5.396625 2.1059999C5.7346249 2.1059999 6.0019374 2.01175 6.1985626 1.8232501C6.3951874 1.63475 6.4935002 1.378 6.4935002 1.053C6.4935002 0.72799999 6.3951874 0.47125 6.1985626 0.28275001C6.0019374 0.094250001 5.7346249 0 5.396625 0L5.396625 0L4.1437502 0L4.1437502 3.3783751L4.723875 3.3783751ZM5.3381252 1.64775L4.723875 1.64775L4.723875 0.45824999L5.3381252 0.45824999C5.5266252 0.45824999 5.6688123 0.50862497 5.7646875 0.609375C5.8605623 0.71012503 5.9085002 0.85799998 5.9085002 1.053C5.9085002 1.248 5.8605623 1.395875 5.7646875 1.4966249C5.6688123 1.597375 5.5266252 1.64775 5.3381252 1.64775L5.3381252 1.64775ZM8.9504995 3.3783751L8.9504995 2.6763749L9.4038754 2.6763749L9.4038754 2.2181251L8.9504995 2.2181251L8.9504995 0L8.4045 0L6.829875 2.2425001L6.829875 2.6763749L8.428875 2.6763749L8.428875 3.3783751L8.9504995 3.3783751ZM8.428875 2.2181251L7.3905001 2.2181251L8.428875 0.73124999L8.428875 2.2181251Z"
		/>
	</svg>
);

export const MOV = () => (
	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<title>icon/file_types/mov</title>
		<g id="💙-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Icons" transform="translate(-1312, -737)">
				<g id="Multi-color" transform="translate(1120, 279)">
					<g id="Row-8" transform="translate(0, 458)">
						<g id="Icon/File-types/MOV" transform="translate(192, 0)">
							<rect id="Base" x="0" y="0" width="24" height="24"></rect>
							<g id="Icon" transform="translate(0.6, 0)">
								<path
									d="M20.3999964,6 L15.15,6 L15.15,6 C14.7357862,6 14.3999964,5.66421736 14.3999964,5.25000361 C14.3999964,5.25000361 14.3999964,5.25000361 14.3999964,5.25000361 L14.3999964,0 L3.52499997,0 L3.525,0 C2.90523281,-0.00155165084 2.40155625,0.499606735 2.39999637,1.11936924 C2.39999637,1.11937158 2.39999637,1.11937393 2.39999637,1.11937628 L2.39999637,22.8750013 L2.39999637,22.8749966 C2.39844217,23.4947638 2.89959843,23.9984451 3.51936093,24 C3.51936328,24 3.51936563,24 3.51936798,24 L19.274993,24 L19.274993,24 C19.8947602,24.0015566 20.3984367,23.5003982 20.3999964,22.8806357 C20.3999964,22.8806333 20.3999964,22.880631 20.3999964,22.8806286 L20.3999964,6 L20.3999964,6 Z"
									id="Base"
									fill="#D3D6E0"
								></path>
								<path
									d="M20.071875,4.92187861 L15.4828125,0.32812861 L15.4828125,0.328128626 C15.2712703,0.117317689 14.9845875,-0.000728561377 14.6859375,2.40285772e-07 L14.4,2.40285772e-07 L14.4,5.25000363 L14.4,5.25000374 C14.4,5.66421749 14.7357867,6.00000024 15.15,6.00000024 L20.4,6.00000024 L20.4,5.71406624 L20.4,5.71405237 C20.4,5.41692581 20.2819664,5.131968 20.0718652,4.92186956 L20.071875,4.92187861 Z"
									id="Thing"
									fill="#ECEFF4"
								></path>
								<g id="Logo" transform="translate(5.4, 10.8)">
									<rect id="Base" x="0" y="0" width="12" height="12"></rect>
									<path
										d="M8.72357611,5.67275896 L4.59846115,3.08177529 C4.26329555,2.87135903 3.75,3.07554996 3.75,3.59598781 L3.75,8.77671009 C3.75,9.24361008 4.22696642,9.52499514 4.59846115,9.29092261 L8.72357611,6.701184 C9.09155511,6.47084668 9.09272702,5.90309629 8.72357611,5.67275896 Z"
										id="Icon"
										fill="#898F9E"
									></path>
								</g>
								<g id="Format" transform="translate(0, 2.4)">
									<rect id="Format-BG" fill="#ED5465" x="0" y="0" width="12" height="7.2" rx="0.6"></rect>
									<path
										d="M1.36125,5.331375 L1.36125,2.811 L2.214375,4.483125 L2.750625,4.483125 L3.633,2.80125 L3.633,5.331375 L4.16925,5.331375 L4.16925,1.953 L3.5355,1.953 L2.497125,3.966375 L1.47825,1.953 L0.825,1.953 L0.825,5.331375 L1.36125,5.331375 Z M6.436125,5.41425 C6.770875,5.41425 7.060125,5.3419375 7.303875,5.1973125 C7.547625,5.0526875 7.732875,4.847125 7.859625,4.580625 C7.986375,4.314125 8.04975,4.002125 8.04975,3.644625 C8.04975,3.277375 7.983125,2.9605 7.849875,2.694 C7.716625,2.4275 7.5289375,2.224375 7.2868125,2.084625 C7.0446875,1.944875 6.761125,1.875 6.436125,1.875 C6.101375,1.875 5.812125,1.9473125 5.568375,2.0919375 C5.324625,2.2365625 5.139375,2.442125 5.012625,2.708625 C4.885875,2.975125 4.8225,3.287125 4.8225,3.644625 C4.8225,4.011875 4.889125,4.32875 5.022375,4.59525 C5.155625,4.86175 5.3433125,5.064875 5.5854375,5.204625 C5.8275625,5.344375 6.111125,5.41425 6.436125,5.41425 Z M6.436125,4.917 C6.107875,4.917 5.857625,4.8089375 5.685375,4.5928125 C5.513125,4.3766875 5.427,4.060625 5.427,3.644625 C5.427,3.228625 5.513125,2.9125625 5.685375,2.6964375 C5.857625,2.4803125 6.107875,2.37225 6.436125,2.37225 C6.764375,2.37225 7.014625,2.4803125 7.186875,2.6964375 C7.359125,2.9125625 7.44525,3.228625 7.44525,3.644625 C7.44525,4.060625 7.359125,4.3766875 7.186875,4.5928125 C7.014625,4.8089375 6.764375,4.917 6.436125,4.917 Z M10.07775,5.331375 L11.30625,1.953 L10.6725,1.953 L9.7755,4.746375 L8.873625,1.953 L8.220375,1.953 L9.448875,5.331375 L10.07775,5.331375 Z"
										id="MOV"
										fill="#FFFFFF"
										fillRule="nonzero"
									></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
