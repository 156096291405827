import React, { useEffect, useMemo } from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { FindResponse } from "@remar/shared/dist/api/baseApiService";
import { ReactComponent as CalenderIcon } from "@remar/shared/dist/assets/icons/icon-calender-filled.svg";
import { ReactComponent as IconClock } from "@remar/shared/dist/assets/icons/icon-clock.svg";
import { ReactComponent as IconEye } from "@remar/shared/dist/assets/icons/icon-eye.svg";
import { Wrapper } from "@remar/shared/dist/layouts";
import { LiveStream } from "@remar/shared/dist/models";
import { convertDuration } from "@remar/shared/dist/utils/convertMinsToHrsMins";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	fetchLiveStreamHistory,
	getLiveStreamFullState,
	resetSelectedLiveStream
} from "store/features/LiveStream/LiveStream.slice";

import { routes } from "core/constants";

import { StreamsWrapper } from "./styles";

const LiveStreams = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { isFetchingHistory, streamHistory } = useSelector(getLiveStreamFullState);

	useEffect(() => {
		dispatch(
			fetchLiveStreamHistory({
				page: 1,
				include: ["allowedLocations"],
				orderBy: { createdAt: "DESC" }
			})
		);
		return () => {
			dispatch(resetSelectedLiveStream());
		};
	}, []);

	const { items } = useMemo(() => {
		if (!streamHistory) {
			return {
				items: []
			} as unknown as FindResponse<LiveStream>;
		}
		const { items, page, perPage } = streamHistory;
		return { items, page, perPage } as FindResponse<LiveStream>;
	}, [streamHistory]);

	return (
		<Wrapper heading={"Live Streams"} showLoader={isFetchingHistory}>
			<StreamsWrapper>
				{items.map(item => (
					<Card
						className={"stream"}
						key={item.id}
						onClick={() => history.push(`${routes.streams.getPath()}/${item.id}/${item.streamId}`)}
					>
						<img src={item.mainImageKey ? item.mainImageUrl : "https://picsum.photos/200/300"} alt="" />
						<Box className={"stream_content"}>
							<Typography className={"title"}>{item.name}</Typography>
							<Typography className={"description"}>{item.description}</Typography>
							<Box className={"stats"}>
								<Box className={"stats_item"}>
									<IconEye />
									<Typography>{item.totalViewers || 0}</Typography>
								</Box>{" "}
								<Box className={"stats_item"}>
									<IconClock />
									<Typography>{convertDuration(item.durationInSeconds)}</Typography>
								</Box>
								<Box className={"stats_item"}>
									<CalenderIcon />
									<Typography>{formatDate(item.createdAt)}</Typography>
								</Box>
							</Box>
						</Box>
					</Card>
				))}
			</StreamsWrapper>
		</Wrapper>
	);
};

export default LiveStreams;
