import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Tooltip } from "@material-ui/core";
import { Notes } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";
import { QuizCalculator } from "@remar/shared/dist/components/QuizCalculator";
import TestResults from "@remar/shared/dist/components/TestResults";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { useCountdown } from "@remar/shared/dist/hooks/useCountdown";
import { useTimer } from "@remar/shared/dist/hooks/useTimer";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { Question } from "@remar/shared/dist/models";
import { UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers/dto";
import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { Prompt, useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";

import { getFullState as getFullAuthState } from "store/features/Auth/authSlice";
import { getQuestionNotes, selectQuestionNotes } from "store/features/Lesson/lesson.slice";
import { fetchQuizzes, getFullState as getFullTestState } from "store/features/Test/test.slice";
import {
	completeCaseStudyQuestion,
	completeUserQuizAttempt,
	createUserQuestionAttempt,
	createUserQuizAttempt,
	getFullTestAttemptState,
	getIsQuizFinished,
	getIsQuizPassed,
	getNextLoadingState,
	getQuizAttempt,
	getQuizAttemptId,
	getQuizError,
	getQuizPercentage,
	getQuizQuestionAnswers,
	getQuizQuestions,
	getQuizResult,
	getUserQuizAttempt,
	moveGroupQuestionAnswerOption,
	quizIsLoading,
	retakeQuiz,
	retakeTest,
	selectOptionsErrorState,
	setCSQuestions,
	setOptionsError,
	updateUserQuestionAttempt
} from "store/features/Test/testAttempt.slice";

import { routes } from "core/constants";
import GroupingQuestion from "modules/Lesson/GroupingQuestion";
import MultipleChoiceQuestion from "modules/Lesson/MultipleChoiceQuestion";
import SequencingQuestion from "modules/Lesson/SequencingQuestion";
import SingleChoiceQuestion from "modules/Lesson/SingleChoiceQuestion";

import { CaseStudyQuestionForTests } from "modules/QuestionBank/Test/Components";
import BowTieQuestion from "modules/QuestionBank/Test/Components/BowTieQuestion";
import CloseDropDownQuestion from "modules/QuestionBank/Test/Components/ClozeDropDownQuestion";
import DragAndDropQuestion from "modules/QuestionBank/Test/Components/DragAndDropQuestion";
import DropDownTableQuestion from "modules/QuestionBank/Test/Components/DropDownTableQuestion";
import HighlightTableQuestion from "modules/QuestionBank/Test/Components/HighlightTableQuestion";
import HotspotHighlightQuestion from "modules/QuestionBank/Test/Components/HotspotHighlightQuestion";
import MatrixMultipleChoiceQuestion from "modules/QuestionBank/Test/Components/MatrixMultipleChoiceQuestion";
import MatrixSingleChoiceQuestion from "modules/QuestionBank/Test/Components/MatrixSingleChoiceQuestion";
import MultipleResponseGroupQuestion from "modules/QuestionBank/Test/Components/MultipleResponseGroupQuestion";

import { useButtonStyles } from "modules/QuestionBank/Test/styles";

import { TestQuizQuestionText } from "modules/QuestionBank/styles";

import { State, getState } from "./utils";

import {
	CalculatorIcon,
	CorrectAnswersIcon,
	CountdownIcon,
	FeedbackBtn,
	FeedbackIcon,
	IncorrectAnswersIcon,
	NavigationBarLeftContainer,
	NavigationBarRightContainer,
	NotesBtn,
	QuizButtonsRow,
	QuizCalculatorButton,
	QuizCountdown,
	QuizCountdownText,
	QuizLessonContainer,
	QuizLessonLessonNumber,
	QuizLessonNavigationBar,
	QuizLessonTestTitle,
	QuizLessonTitle,
	QuizPercentage,
	QuizQuestionContainer,
	QuizQuestionContent,
	QuizQuestionTitle,
	QuizResult,
	QuizResultButtons,
	QuizResultHeader,
	QuizResultSummary,
	QuizResultSummaryRow,
	QuizResultTitle,
	QuizStart
} from "../Lesson/style";

type VisibleUtility = "calculator" | "notes" | "feedback" | null;

const IsSequencingOrGrouping = questionTypeId => {
	return [QuestionTypes.Grouping, QuestionTypes.Sequencing].includes(questionTypeId);
};

const getQuizTimeRemaining = (attempt, durationInMinutes) => {
	const startDateTime = new Date(attempt?.createdAt);
	const endTime = startDateTime?.getTime() + durationInMinutes * 60 * 1000;
	const currentTime = Date.now();
	return +((endTime - currentTime) / 1000).toFixed(0);
};

const TakeTests = () => {
	const classes = useButtonStyles();
	const ref = useRef({});
	const csRef = useRef({});
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { id: quizIds } = useParams<{ id: string }>();
	const quizId = useMemo(() => parseInt(quizIds), [quizIds]);

	const isQuizFinished = useAppSelector(getIsQuizFinished);
	const { name, showQuizResults, durationInMinutes, isTestStartLoading, isRetake, isQuizResultLoading } =
		useAppSelector(getFullTestAttemptState);
	const savedUserAnswers = useAppSelector(getQuizQuestionAnswers);
	const quizError = useAppSelector(getQuizError);
	const questions = useAppSelector(getQuizQuestions);
	const quizResult = useAppSelector(getQuizResult);
	const quizAttempt = useAppSelector(getQuizAttempt);
	const quizPassed = useAppSelector(getIsQuizPassed);
	const quizPercentage = useAppSelector(getQuizPercentage);
	const isNextLoading = useAppSelector(getNextLoadingState);
	const isLoading = useAppSelector(quizIsLoading);
	const attemptId = useAppSelector(getQuizAttemptId);
	const { user } = useAppSelector(getFullAuthState);
	const { items, isLoading: isTestLoading } = useAppSelector(getFullTestState);
	const optionError = useAppSelector(selectOptionsErrorState);
	const { text: notes, questionId: notesQuestionId } = useAppSelector(selectQuestionNotes);

	const isAttemptedCountExceeded = quizAttempt
		? quizAttempt?.data.data.maxAttempts === quizAttempt?.attemptCount
		: false;
	const testState = quizAttempt ? getState(quizAttempt?.data.liveAfter, quizAttempt?.data.expiresOn) : State.Active;

	const [questionIndex, setQuestionIndex] = useState(0);
	const [question, setQuestion] = useState<Question>();
	const typeId = useMemo(() => (question ? question.typeId : null), [question]);
	const [questionId, setQuestionId] = useState<number>();
	const [isStarted, setIsStarted] = useState(true);
	const [isFinished, setIsFinished] = useState(isQuizFinished);

	const [showResults, setShowResults] = useState(false);
	const [resetCountdown, setResetCountdown] = useState(false);
	const [FailureModal, setFailureModal] = useState<boolean>(false);
	const [timeSpentOnLatestQuestion, setTimeSpentOnLatestQuestion] = useState(0);
	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>([]);
	const [isNextDisabled, setIsNextDisabled] = useState(typeId === QuestionTypes.CaseStudy);
	const [visibleUtility, setVisibleUtility] = useState<VisibleUtility>(null);
	const [showNotes, setShowNotes] = useState(false);
	const [showSyncTimeModal, setShowSyncTimeModal] = useState(false);

	const analytics = useAnalyticsEventTracker("Lesson");
	const { seconds, startOrResetTimer, stopTimer } = useTimer();

	const {
		secondsToDisplay,
		minutesToDisplay,
		hoursToDisplay,
		startCountdown,
		stopCountdown,
		handleReset,
		isTimeSynced
	} = useCountdown({
		totalTimeInMinutes: durationInMinutes,
		startTime: quizAttempt?.createdAt,
		onExpire: () => {
			if (getQuizTimeRemaining(quizAttempt, durationInMinutes) <= 0) {
				quizAttempt && quizAttempt.inProgress && dispatch(completeUserQuizAttempt({ id: attemptId }));
				setIsFinished(true);
				setVisibleUtility(null);
				stopTimer();
			} else {
				startCountdown();
			}
		}
	});

	useEffect(() => {
		if (!isTimeSynced && durationInMinutes && isStarted) {
			stopCountdown();
			setIsFinished(true);
			setShowSyncTimeModal(true);
		}
	}, [isTimeSynced, durationInMinutes, isStarted]);
	const locationId = useMemo(() => (!!user?.allowedLocations?.length ? user?.allowedLocations[0].id : null), [user]);

	useEffect(() => {
		if (questions.length !== 0) {
			setQuestion(questions[questionIndex]);
			setQuestionId(questions[questionIndex].id);
		}
	}, [questions, questionIndex]);

	useEffect(() => {
		if (!name && locationId) {
			dispatch(fetchQuizzes({ page: 1, locationId }));
		}
	}, [dispatch, name, locationId]);

	useEffect(() => {
		if (quizId) {
			dispatch(getUserQuizAttempt({ quizId }));
		}

		return () => {
			setOptionsError("");
			setShowResults(false);
		};
	}, [dispatch, quizId]);

	useEffect(() => {
		if (questionId && userAnswers) ref.current[questionId] = userAnswers;
	}, [userAnswers, questionId]);

	useEffect(() => {
		if (questionId) {
			setUserAnswers(savedUserAnswers![questionId]?.answers || []);
		}
	}, [questionId, setUserAnswers, savedUserAnswers]);

	useEffect(() => {
		if (!isFinished && !resetCountdown) {
			window.onbeforeunload = alertUser;
			return () => {
				window.onbeforeunload = () => null;
			};
		} else {
			window.onbeforeunload = () => null;
		}
	}, [isFinished, resetCountdown]);

	useEffect(() => {
		if (!attemptId) {
			setIsStarted(false);
			setIsFinished(false);
			setQuestionIndex(0);
		}
	}, [attemptId]);

	useEffect(() => {
		if (secondsToDisplay === 0 && minutesToDisplay == 0 && hoursToDisplay === 0 && quizAttempt) {
			stopCountdown();
		}
	}, [secondsToDisplay]);

	useEffect(() => {
		if (getQuizTimeRemaining(quizAttempt, durationInMinutes) > 0 && isStarted && !isFinished) {
			startCountdown();
		}
	}, [quizId, isStarted, isFinished, quizAttempt?.createdAt]);

	useEffect(() => {
		setShowResults(false);
	}, [quizId]);

	useEffect(() => {
		if (attemptId && !isStarted) {
			if (getQuizTimeRemaining(quizAttempt, durationInMinutes) < 0) {
				setIsStarted(false);
				setIsFinished(true);
			} else {
				setIsStarted(true);
				setIsFinished(false);
				if (questionId && typeId !== QuestionTypes.Grouping) {
					const userAnswerOption = savedUserAnswers![questionId]?.answers ?? [];
					setUserAnswers(userAnswerOption.map(id => id));
				}
				// selects the first unanswered question from `questions` array,
				// fallback to 0 index in case all questions were answered
				const index = questions.findIndex(({ id }) => !savedUserAnswers?.[id]);
				setQuestionIndex(index < 0 ? 0 : index);
			}
		}
	}, [attemptId, isStarted, typeId, quizAttempt?.createdAt, questionId]);

	useEffect(() => {
		if (attemptId && questionId) {
			const questionAttemptTimeSpent =
				(savedUserAnswers && savedUserAnswers[questionId]?.timeSpent) || timeSpentOnLatestQuestion;
			startOrResetTimer(questionAttemptTimeSpent);
		}
	}, [questionIndex, savedUserAnswers, questionId, attemptId]);

	useEffect(() => {
		if (!FailureModal) {
			dispatch(setOptionsError(""));
		}
	}, [FailureModal, dispatch]);

	useEffect(() => {
		if (optionError !== "") {
			setFailureModal(true);
		}
	}, [optionError]);

	useEffect(() => {
		IsSequencingOrGrouping(question?.typeId) && setIsNextDisabled(false);
	}, [question?.typeId, question?.id]);

	useEffect(() => {
		question?.typeId !== QuestionTypes.CaseStudy &&
			!IsSequencingOrGrouping(question?.typeId) &&
			getQuestionTypeValidation(question?.typeId, question, userAnswers);
	}, [question?.typeId, userAnswers, question]);

	useEffect(() => {
		if (questions.length > 0 && attemptId && quizAttempt && quizAttempt.inProgress && isStarted && !isFinished) {
			const lastQuestionId = questions[questions.length - 1].id;
			if (getQuizTimeRemaining(quizAttempt, durationInMinutes) <= 0 || savedUserAnswers![lastQuestionId]) {
				dispatch(completeUserQuizAttempt({ id: attemptId }));
				setIsFinished(true);
				setVisibleUtility(null);
				stopCountdown();
			}
		}
	}, [attemptId, isStarted, savedUserAnswers, isFinished, quizAttempt, questions, dispatch]);

	const alertUser = e => {
		e.preventDefault();
		e.returnValue = "";
	};

	const handleStart = () => {
		setIsStarted(true);
		if (resetCountdown) {
			window.onbeforeunload = alertUser;
			handleReset();
			setResetCountdown(resetCountdown => !resetCountdown);
		}

		if (isRetake) {
			dispatch(retakeQuiz({ quizId })).catch(() => setIsStarted(false));
		} else {
			!attemptId && quizId && dispatch(createUserQuizAttempt({ quizId }));
		}
	};

	const moveToNextQuestion = () => {
		if (questionIndex < questions.length - 1) {
			setQuestionIndex(questionIndex + 1);
		}
	};

	const handleNext = () => {
		if (question && (userAnswers.length > 0 || question.typeId === QuestionTypes.CaseStudy)) {
			if (question.typeId === QuestionTypes.MatrixSingleChoice && question.data.groups?.length !== userAnswers.length) {
				return;
			}

			if (question.typeId === QuestionTypes.CaseStudy) {
				questions[questions.length - 1].id === question.id &&
					dispatch(completeCaseStudyQuestion({ questionId: question.id }));

				if (questionIndex < questions.length - 1 && !optionError) {
					setQuestionIndex(questionIndex + 1);
				}
			} else if (attemptId && questionId && !savedUserAnswers![questionId]) {
				setTimeSpentOnLatestQuestion(0);
				dispatch(
					createUserQuestionAttempt({
						userQuizAttemptId: attemptId,
						questionId: question.id,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: moveToNextQuestion
					})
				);
			} else if (attemptId) {
				dispatch(
					updateUserQuestionAttempt({
						userQuizAttemptId: savedUserAnswers![questionId]?.id,
						userAnswers: [...userAnswers],
						timeSpent: seconds,
						sideEffect: moveToNextQuestion
					})
				);
			} else if (questionIndex !== questions.length - 1) {
				setQuestionIndex(questionIndex + 1);
			}
		}
	};

	const handlePrev = () => {
		if (questionId && savedUserAnswers![questionId]?.id) {
			dispatch(
				updateUserQuestionAttempt({
					userQuizAttemptId: savedUserAnswers![questionId]?.id,
					userAnswers: [...userAnswers],
					timeSpent: seconds
				})
			);
		} else {
			setTimeSpentOnLatestQuestion(seconds);
		}
		setQuestionIndex(questionIndex - 1);
	};

	const checkAllSelection = (question, options, customUserAnswers) => {
		const filteredGroups = question.data?.groups?.filter(group => group[options]) || [];
		return !filteredGroups.length === customUserAnswers.length;
	};

	function getQuestionTypeValidation(id, question, customUserAnswers) {
		switch (id) {
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.DropDownTable:
				setIsNextDisabled(checkAllSelection(question, "answerOptions", customUserAnswers));
				break;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.MatrixSingleChoice:
				setIsNextDisabled(checkAllSelection(question, "selectedAnswerOptions", customUserAnswers));
				break;
			case QuestionTypes.MatrixMultipleChoice: {
				const isAllColumnsSelected = question.data.groups.every(group =>
					customUserAnswers.some(answer => answer.groupId === group.id)
				);
				setIsNextDisabled(!isAllColumnsSelected);
				break;
			}
			case QuestionTypes.HotspotHighlight:
				setIsNextDisabled(customUserAnswers.length <= 0);
				break;
			case QuestionTypes.MultipleResponseGroup:
			case QuestionTypes.HighlightTable:
				const isAllSelected = question.data?.groups?.every(group =>
					customUserAnswers.some(answer => answer.groupId === group.id)
				);
				setIsNextDisabled(!isAllSelected);
				break;
			case QuestionTypes.BowTie:
				setIsNextDisabled(customUserAnswers.length < 5);
				break;
			default:
				setIsNextDisabled(customUserAnswers.length === 0);
				break;
		}
	}

	const getQuestionType = id => {
		if (!questionId || !question) {
			return;
		}
		switch (id) {
			case QuestionTypes.DropDownTable:
				return (
					<DropDownTableQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.BowTie:
				return (
					<BowTieQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
						answersRef={ref}
					/>
				);
			case QuestionTypes.MultipleResponseGroup:
				return (
					<MultipleResponseGroupQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.HighlightTable:
				return (
					<HighlightTableQuestion
						question={question}
						onChange={setUserAnswers}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.MatrixMultipleChoice:
				return (
					<MatrixMultipleChoiceQuestion
						key={question.id}
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return (
					<DragAndDropQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						answersRef={ref}
						key={question.id}
					/>
				);
			case QuestionTypes.HotspotHighlight:
				return (
					<HotspotHighlightQuestion
						question={question}
						onChange={setUserAnswers}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <CloseDropDownQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.CaseStudy:
				return (
					<CaseStudyQuestionForTests
						question={question}
						key={`case-study-${questionIndex}`}
						questionIndex={questionIndex}
						csRef={csRef}
						attemptId={attemptId}
						setCSQuestions={setCSQuestions}
						loading={isNextLoading}
						setIsNextDisabled={setIsNextDisabled}
					/>
				);
			case QuestionTypes.MatrixSingleChoice:
				return (
					<MatrixSingleChoiceQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.SingleChoice:
				return <SingleChoiceQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
			case QuestionTypes.MultipleChoice:
				return <MultipleChoiceQuestion question={question} onChange={setUserAnswers} userAnswers={userAnswers} />;
			case QuestionTypes.Grouping:
				return (
					<GroupingQuestion
						question={question}
						questionIndex={questionIndex}
						uniqueIdentifier={`${isStarted}-${attemptId}-${id}`}
						dispatchAction={moveGroupQuestionAnswerOption}
						setUserAnswers={setUserAnswers}
						answersRef={ref}
					/>
				);
			case QuestionTypes.Sequencing:
				return (
					<SequencingQuestion
						question={question}
						onChange={setUserAnswers}
						userAnswers={userAnswers}
						answersRef={ref}
					/>
				);
			default:
				return null;
		}
	};

	const handleShowNotes = questionId => {
		if (notesQuestionId !== questionId) {
			dispatch(getQuestionNotes(questionId));
		}
		analytics({ eventName: "hit_close_extended_results", eventIdentifier: `${questionId}` });
		setShowNotes(true);
	};

	if (isLoading || isTestLoading || isQuizResultLoading) {
		return <ContentLoader />;
	}

	const getView = () => {
		if (isStarted && questions.length === 0 && !quizError) {
			return null;
		}
		if (showResults) {
			return (
				<TestResults
					results={quizResult}
					onClose={() => {
						setShowResults(false);
						setShowNotes(false);
					}}
					onNextLesson={() => {}}
					showNextLesson={false}
					userAttemptedQuestions={questions}
					handleShowNotes={handleShowNotes}
					notes={notes}
					showNotes={showNotes}
					setShowNotes={setShowNotes}
				/>
			);
		}
		if (!isStarted) {
			return (
				<QuizStart>
					<QuizLessonTitle>{name || items?.[0]?.name}</QuizLessonTitle>
					<Button variant="filled" color="primary" onClick={handleStart} loading={isTestStartLoading}>
						Start the Quiz
					</Button>
				</QuizStart>
			);
		}
		if (quizError) {
			return quizError;
		}
		if (!isFinished && !isQuizFinished) {
			return (
				<>
					<QuizLessonTestTitle>{name || items?.[0]?.name}</QuizLessonTestTitle>
					<QuizLessonNavigationBar>
						<NavigationBarLeftContainer>
							<NotesBtn disabled>
								<Notes style={{ width: "20px", height: "20px", fill: "#b6e0fe" }} />
								Notes
							</NotesBtn>
							<QuizCalculatorButton onClick={() => setVisibleUtility("calculator")}>
								<CalculatorIcon /> Calculator
							</QuizCalculatorButton>
							<FeedbackBtn disabled>
								<Tooltip title="Feedback was already sent" disableHoverListener>
									<>
										<FeedbackIcon /> Feedback
									</>
								</Tooltip>
							</FeedbackBtn>
						</NavigationBarLeftContainer>
						<NavigationBarRightContainer>
							<QuizLessonLessonNumber>
								{questionIndex + 1}
								<span> / {questions.length}</span>
							</QuizLessonLessonNumber>
							<QuizCountdown>
								<CountdownIcon />
								<QuizCountdownText>Time Left</QuizCountdownText> {hoursToDisplay}:
								{minutesToDisplay.toString().length < 2 ? String(minutesToDisplay).padStart(2, "0") : minutesToDisplay}:
								{String(secondsToDisplay).padStart(2, "0")}
							</QuizCountdown>
						</NavigationBarRightContainer>
					</QuizLessonNavigationBar>
					<QuizQuestionContainer>
						<QuizQuestionContent>
							<QuizQuestionTitle>Question {questionIndex + 1}</QuizQuestionTitle>
							<TestQuizQuestionText pl={"14px"} dangerouslySetInnerHTML={{ __html: question?.description }} />
							<TestQuizQuestionText pl={"14px"}>Question: {getSanitizedHtmlText(question?.text)}</TestQuizQuestionText>
							<Box my={1}>{question && getQuestionType(question.typeId)}</Box>
						</QuizQuestionContent>
						{visibleUtility === "calculator" && (
							<QuizCalculator onClose={() => setVisibleUtility(null)} backgroundColor={"#eceff4"} />
						)}
					</QuizQuestionContainer>
					<QuizButtonsRow>
						{questionIndex > 0 && (
							<Button variant="filled" onClick={handlePrev}>
								Previous Question
							</Button>
						)}
						<Button
							classes={{ disabled: classes.disabledButton, containedPrimary: classes.testContainedPrimary }}
							variant="filled"
							color="primary"
							onClick={handleNext}
							disabled={isNextDisabled}
						>
							{questionIndex === questions.length - 1 ? "Finish" : "Next Question"}
						</Button>
					</QuizButtonsRow>
				</>
			);
		}
		return (
			<QuizResult>
				<QuizResultHeader>{quizPassed ? "Test passed" : "Test failed"}</QuizResultHeader>
				<QuizResultTitle>
					{quizPassed ? (
						<>
							Congrats!
							<br />
							You have Passed the test
						</>
					) : (
						<>
							Try Again!
							<br />
							You have Failed the test
						</>
					)}
				</QuizResultTitle>
				<QuizResultSummary>
					<QuizPercentage passed={quizPassed}>
						<div>Your score:</div>
						{quizPercentage}%
					</QuizPercentage>
					<div>
						<QuizResultSummaryRow>
							<CorrectAnswersIcon /> {quizResult.filter(({ isCorrect }) => isCorrect).length} questions correct
						</QuizResultSummaryRow>
						<QuizResultSummaryRow>
							<IncorrectAnswersIcon /> {quizResult.filter(({ isCorrect }) => !isCorrect).length} questions incorrect
						</QuizResultSummaryRow>
					</div>
				</QuizResultSummary>
				<QuizResultButtons>
					<Box display={"flex"} justifyContent={"center"}>
						<Button
							variant="filled"
							color="primary"
							onClick={() => history.push(routes.tests.getPath())}
							style={{ marginRight: 15 }}
						>
							Go Back
						</Button>
						{quizPassed ? (
							<Box>
								{!!showQuizResults && (
									<Button variant="filled" color="secondary" onClick={() => setShowResults(true)}>
										View results
									</Button>
								)}
							</Box>
						) : isAttemptedCountExceeded || testState === State.Expired ? (
							<Box>
								{!!showQuizResults && (
									<Button variant="filled" color="secondary" onClick={() => setShowResults(true)}>
										View results
									</Button>
								)}
							</Box>
						) : (
							<Button variant="filled" color="primary" onClick={() => dispatch(retakeTest())}>
								Retake test
							</Button>
						)}
					</Box>
				</QuizResultButtons>
			</QuizResult>
		);
	};

	return (
		<Box m={2}>
			<QuizLessonContainer>
				<Prompt
					when={!isFinished && !isQuizFinished}
					message="A test is in progress. Are you sure you want to leave?"
				/>
				{getView()}
			</QuizLessonContainer>
			<SimpleModal
				title="Please select options again"
				open={FailureModal}
				onClose={() => setFailureModal(false)}
				text={optionError}
				footer={
					<>
						<Button variant="filled" color="primary" size="medium" onClick={() => setFailureModal(false)}>
							Ok
						</Button>
					</>
				}
			/>
			<SimpleModal
				title="Sync Device Time"
				open={showSyncTimeModal}
				onClose={() => setShowSyncTimeModal(false)}
				text={"Please make sure your device time is synced take tests"}
				footer={
					<Button variant="filled" color="primary" size="medium" onClick={() => setShowSyncTimeModal(false)}>
						Ok
					</Button>
				}
			/>
		</Box>
	);
};

export default TakeTests;
