import { useCallback } from "react";

import { ToastPosition, toast } from "react-toastify";

interface IOptions {
	toastId?: string | number;
	position?: ToastPosition;
	hideProgressBar?: boolean;
	autoClose?: number | false;
	style?: Record<string, string>;
	className?: string;
	bodyClassName?: string;
	closeOnClick?: boolean;
}

const _initialOptions: IOptions = { position: "bottom-right", hideProgressBar: true, autoClose: 2000 };

const useToastNotifications = () => {
	const pushToastNotification = useCallback((content: string | React.ReactNode, options?: IOptions) => {
		const { toastId } = options || {};

		if (toast.isActive(toastId || "")) {
			toast.update(toastId as string | number, {
				..._initialOptions,
				...{
					...options,
					render: content
				}
			});
		} else {
			toast(content, { ..._initialOptions, ...options });
		}
	}, []);

	const dismissToastNotification = (id: string) => toast.dismiss(id);

	return { pushToastNotification, dismissToastNotification };
};

export default useToastNotifications;
