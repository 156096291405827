import React, { memo, useEffect, useMemo } from "react";

import { IconButton, Tooltip } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";

import Button from "@remar/shared/dist/components/Button";
import { PushNotification } from "@remar/shared/dist/components/Notifications";
import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import { TopNav as SharedTopNav } from "@remar/shared/dist/layouts/BaseLayout";
import {
	ButtonActionEnum,
	ISocketNotificationItem,
	NotificationButtonDataDto,
	NotificationTypeId
} from "@remar/shared/dist/models";
import useFirebaseNotifications from "@remar/shared/dist/services/useFirebaseNotifications";
import useToastNotifications from "@remar/shared/dist/services/useToastNotifications";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";
import { isAfter } from "date-fns";
import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
	applyValidatedCoupon,
	getFullState,
	getQbNextSubscriptions,
	logout,
	selectIsTrial,
	setPaymentDiscount,
	setStateValue,
	setUpgradeModal,
	updateBannerData
} from "store/features/Auth/authSlice";
import { setChangeDisplaySubscriptionDateModal } from "store/features/MyAccount/myAccountSlice";
import {
	getFullState as getFullNotifications,
	getUserNotifications,
	markAsRead,
	setBannerData
} from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

import Banner from "./Banner";
import BellNotifications from "./BellNotifications";
import SearchBar from "./SearchBar";

import { getLatestSentBannerNotification, getLatestSentPushNotifications } from "./utils";

const TopNav = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { user: userInfo } = useAppSelector(getFullState);
	const isTrial = useAppSelector(selectIsTrial);
	const { onDeleteFcmToken } = useFirebaseNotifications();
	const { pushToastNotification, dismissToastNotification } = useToastNotifications();
	const { bannerNotifications, bellNotifications, bannerData } = useAppSelector(getFullNotifications);
	const analytics = useAnalyticsEventTracker("User");

	const hasQBOnlySubscription = useMemo(
		() =>
			userInfo?.subscriptions?.some(
				s => s.type?.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.QuestionBank
			),
		[userInfo?.subscriptions]
	);
	const BellNotificationsList = useMemo(() => bellNotifications?.items || [], [bellNotifications]);

	useEffect(() => {
		dispatch(
			getUserNotifications({
				filters: {
					"notification.notificationTypeId": [
						NotificationTypeId.SystemNotification,
						NotificationTypeId.AdminGeneratedBanner,
						NotificationTypeId.AdminGeneratedPush,
						NotificationTypeId.AdminGeneratedAnnouncement
					]
				},
				findAll: true,
				include: ["notification.coupon.courses", "notification.coupon.products", "notification.paymentAndDiscount"]
			})
		);
	}, [dispatch]);

	const BannerNotifications = useMemo(() => bannerNotifications?.items || [], [bannerNotifications]);

	useEffect(() => {
		if (hasQBOnlySubscription) {
			dispatch(
				setBannerData({
					showBanner: true,
					bannerMessage: "To access the full content lectures click 𝗛𝗲𝗿𝗲!",
					isTrial,
					isDismissible: false,
					takeOver: true,
					action: () => dispatch(getQbNextSubscriptions())
				})
			);
		}
	}, [dispatch, isTrial, hasQBOnlySubscription]);

	useEffect(() => {
		if (!!getCurrentSubscription(userInfo?.subscriptions)?.isPaused) {
			dispatch(
				setBannerData({
					showBanner: true,
					bannerMessage: "Your subscription is currently paused. Please click to resume.",
					isTrial,
					isDismissible: false,
					takeOver: false,
					action: () => dispatch(setChangeDisplaySubscriptionDateModal(true))
				})
			);
		}
	}, [dispatch, isTrial, userInfo?.subscriptions]);

	const handleSetBanner = BannerNotifications => {
		const latestNotification = getLatestSentBannerNotification(BannerNotifications, isTrial, hasQBOnlySubscription);
		if (!latestNotification) {
			// if no onDemand banners update trial banners
			return userInfo && dispatch(updateBannerData(userInfo));
		}
		const {
			createdAt,
			expiresAt,
			notification: {
				id,
				body,
				duration,
				coupon,
				paymentAndDiscount,
				isDismissible,
				countDown,
				expiresAt: notificationExpiresAt,
				data
			}
		} = latestNotification;
		let actionButton: React.JSX.Element | null = null;
		type actionType = (() => void) | undefined;
		let action: actionType = undefined;
		const _expiresAt: string = expiresAt || (notificationExpiresAt as string);
		const canApplyCoupon = _expiresAt && isAfter(new Date(_expiresAt), new Date());
		const showCloseIcon = data?.primaryButton?.action === ButtonActionEnum.Dismiss ? false : isDismissible;
		if (isTrial) {
			if (!!coupon && canApplyCoupon) {
				action = () => {
					dispatch(applyValidatedCoupon(coupon));
					dispatch(setUpgradeModal(true));
				};
			} else if (!!paymentAndDiscount) {
				action = () => {
					dispatch(setPaymentDiscount(paymentAndDiscount));
					dispatch(setUpgradeModal(true));
				};
			} else {
				action = () => {
					dispatch(setStateValue({ key: "validatedCoupon", value: null }));
					dispatch(setUpgradeModal(true));
				};
			}
		}

		if (data?.primaryButton && data?.primaryButton?.action) {
			// setting banner actions based on assigned action
			const { text, action: buttonAction, url } = data.primaryButton;
			const _action = () => {
				switch (buttonAction) {
					case ButtonActionEnum.ApplyCoupon: {
						if (!!coupon) {
							dispatch(applyValidatedCoupon(coupon));
							dispatch(setUpgradeModal(true));
							dispatch(markAsRead({ id: id!, notificationType: NotificationTypeId.AdminGeneratedBanner }));
						}
						break;
					}
					case ButtonActionEnum.OpenLink: {
						window.open(url, "_blank");
						dispatch(markAsRead({ id: id!, notificationType: NotificationTypeId.AdminGeneratedBanner }));
						break;
					}
					default: {
						//	dismiss case
						dispatch(setBannerData({ showBanner: false }));
						dispatch(markAsRead({ id: id!, notificationType: NotificationTypeId.AdminGeneratedBanner }));
					}
				}
			};
			// banner action button
			actionButton = (
				<Button variant={"filled"} color={"secondary"} size={"small"} onClick={_action}>
					{text}
				</Button>
			);
			// action for whole banner
			action = _action;
		}
		dispatch(
			setBannerData({
				showBanner: true,
				bannerMessage: body,
				isTrial: isTrial || hasQBOnlySubscription,
				isDismissible: showCloseIcon,
				countDown,
				duration,
				createdAt,
				expiresAt: _expiresAt,
				takeOver: true,
				action,
				actionButton
			})
		);
	};

	useEffect(() => {
		if (BannerNotifications.length) {
			// initially sets banners after fetching notifications API
			handleSetBanner(BannerNotifications);
		}
	}, [BannerNotifications, hasQBOnlySubscription, isTrial]);

	const handlePushNotificationSecondaryButtonClick = notification => {
		dismissToastNotification("client-push-notification");
		dispatch(markAsRead({ id: notification.notification.id, notificationType: NotificationTypeId.AdminGeneratedPush }));
	};

	const handlePushNotificationPrimaryButtonClick = (
		button: NotificationButtonDataDto,
		notification: ISocketNotificationItem
	) => {
		switch (button.action) {
			case ButtonActionEnum.ApplyCoupon: {
				const coupon = notification.notification.coupon;
				if (!!coupon) {
					dispatch(applyValidatedCoupon(coupon));
					dispatch(setUpgradeModal(true));
					handlePushNotificationSecondaryButtonClick(notification);
				}
				break;
			}
			case ButtonActionEnum.OpenLink: {
				window.open(button.url, "_blank");
				break;
			}
			default: {
				// dismiss case
				handlePushNotificationSecondaryButtonClick(notification);
			}
		}
	};

	useEffect(() => {
		if (BellNotificationsList.length) {
			const latestPushNotification = getLatestSentPushNotifications(
				BellNotificationsList,
				isTrial,
				hasQBOnlySubscription
			);
			if (latestPushNotification) {
				pushToastNotification(
					<PushNotification
						notification={latestPushNotification}
						onSecondaryButtonClick={(_, n) => handlePushNotificationSecondaryButtonClick(n)}
						onPrimaryButtonClick={handlePushNotificationPrimaryButtonClick}
					/>,
					{
						toastId: "client-push-notification",
						autoClose: false,
						className: "wide",
						closeOnClick: false
					}
				);
			}
		}
	}, [BellNotificationsList, isTrial, hasQBOnlySubscription]);

	const handleTimerExpiry = () => {
		// triggered when banner timer ends to remove expured banners
		if (BannerNotifications.length) {
			handleSetBanner(BannerNotifications);
		}
	};

	return (
		<SharedTopNav
			userInfo={userInfo}
			banner={bannerData?.showBanner ? <Banner bannerData={bannerData} timerExpired={handleTimerExpiry} /> : undefined}
			searchbar={<SearchBar />}
			accountPath={routes.myAccount.getPath()}
			onLogOut={async () => {
				dispatch(logout());
				await onDeleteFcmToken();
				analytics({ eventName: "logout" });
			}}
			topMenuItems={
				<>
					{/*	<MessageIcon>
						<Icon name="message-linear" height={19} width={22} fill="#2a2e37" />
						</MessageIcon>
					*/}
					<BellNotifications />
					<Tooltip title="FAQ">
						<IconButton
							size="small"
							onClick={() => {
								if (location.pathname === routes.help.getPath()) history.go(0);
								else history.push(routes.help.getPath());
							}}
						>
							<HelpRounded style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
						</IconButton>
					</Tooltip>
				</>
			}
		/>
	);
};

export default memo(TopNav);
