import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "@remar/shared/dist/components/Button";
import Stepper from "@remar/shared/dist/components/Stepper";
import { SignupStepsLabels } from "@remar/shared/dist/constants";
import useStateWithCallback from "@remar/shared/dist/hooks/useStateWithCallback";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Country, IErrorResponseData } from "@remar/shared/dist/models";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, giftCardSignUp, verifyGiftCardCode } from "store/features/Auth/authSlice";

import { USA_COUNTRY_ID } from "store/features/Auth/constants";

import { IGiftSignUpFormValues } from "store/services";

import { RemarLogoSvg } from "assets/icons";
import logo from "assets/images/logo.svg";
import { routes } from "core/constants";
import { GiftFinalScreenLayout, GiftFinalScreenTitle, GiftFinalScreenWrapper } from "modules/Shop/styles";

import Summary from "./GiftSignUpSummary";

import SignUpLayout from "../SignUpLayout";
import FormActions from "../components/FormActions";
import { AccountFormDetails, ShippingForm } from "../components/Forms";
import SignUpFollowUpScreen from "../components/SignUpFollowUpScreen";
import { AccountSchema, ShippingSchema } from "../components/schemas";
import { CardsContainer, useStyles } from "../components/styles";
import { invitationSignUpFormKeys, validateFormValues } from "../components/utils";

const enum Steps {
	Greeting = 0,
	Account = 1,
	Shipping = 2,
	Success = 3,
	Error = 4
}

const steps = [{ label: SignupStepsLabels.account }, { label: SignupStepsLabels.shipping }];

const Logo = () => (
	<Box display={"flex"} justifyContent={"center"}>
		<img alt="institution logo" src={logo} height={50} />
	</Box>
);

const GreetingScreen = ({ isLoading, setActiveStep, invitationError }) => (
	<GiftFinalScreenLayout>
		<GiftFinalScreenWrapper>
			<RemarLogoSvg />
			<GiftFinalScreenTitle>You have been invited to ReMar via Free Sign up Gift Card!</GiftFinalScreenTitle>

			<Box mt={8}>
				{!!invitationError ? (
					<Typography align="center">{invitationError}</Typography>
				) : (
					<>
						<Typography>Hi there,</Typography>
						<br />
						<Typography>
							You have been invited to join ReMar as a Student. Click the button below to create a free student account.
						</Typography>
					</>
				)}
			</Box>
			<Box maxWidth={300} margin="70px auto 0">
				<Button
					variant="filled"
					loading={isLoading}
					disabled={isLoading || !!invitationError}
					color="primary"
					onClick={() => setActiveStep(Steps.Account)}
				>
					Create an Account
				</Button>
			</Box>
		</GiftFinalScreenWrapper>
	</GiftFinalScreenLayout>
);

const SignUpForm = ({ activeStep, setActiveStep }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const classes = useStyles();

	const [country, setCountry] = useState<Country>();
	const [showContinueButton, setShowContinueButton] = useState(true);

	const { isLoading, selectedCountryId, countries, giftCardData, invitationError } = useAppSelector(getFullState);

	const { recipientCode, recipientEmail } = giftCardData || {};
	const [firstName, lastName] = giftCardData?.recipientName.split(" ");
	const initialValues: IGiftSignUpFormValues = useMemo(
		() => ({
			paymentProviderId: 1,
			firstName,
			lastName,
			email: recipientEmail,
			startDate: "",
			password: "",
			address1: "",
			address2: "",
			city: "",
			zip: "",
			state: "",
			country: ""
		}),
		[firstName, lastName, recipientEmail]
	);

	const hasPhysicalBooks = false; // todo add logic to check if user has physical books after we resolve the concern if we should merge books from the course with gifted books

	const validationSchema = useMemo(() => {
		switch (activeStep) {
			case Steps.Account:
				return AccountSchema;
			case Steps.Shipping:
				return ShippingSchema(hasPhysicalBooks, countries);
		}
	}, [activeStep, countries, hasPhysicalBooks]);

	const handleSubmit = values => {
		dispatch(giftCardSignUp({ ...values, inviteCode: recipientCode })).then(r => {
			if (r.payload.error) {
				setShowContinueButton(true);
			}

			setActiveStep(r.payload.error ? Steps.Error : Steps.Success);
		});
	};

	const handleNext = (values, validateForm = () => {}) => {
		if (activeStep === Steps.Shipping) {
			handleSubmit(values);
		} else {
			setActiveStep(
				s => s + 1,
				() => validateForm()
			);
		}
	};

	const handleClickFollowUp = () => {
		if (activeStep === Steps.Success) {
			history.replace(routes.signIn.getPath());
		} else {
			return Steps.Shipping;
		}
	};

	switch (activeStep) {
		case Steps.Account:
		case Steps.Shipping:
			return (
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnChange
					validateOnBlur={false}
					onSubmit={values => handleNext(values)}
				>
					{({ isValid, values, setErrors, setFieldTouched, handleChange, validateForm, dirty, errors, resetForm }) => {
						let valid = isValid && isEmpty(errors) && dirty;
						if (!dirty && activeStep !== Steps.Success && activeStep !== Steps.Error) {
							valid = validateFormValues(invitationSignUpFormKeys[activeStep], values);
						}
						return (
							<>
								<Card className={`${classes.card} ${classes.baseFlex}`}>
									<Box>
										<Box px={6} pt={2} className={classes.stepperForm}>
											{activeStep === Steps.Shipping && selectedCountryId && selectedCountryId !== USA_COUNTRY_ID && (
												<Alert style={{ margin: "16px 0" }} severity="info">
													Payment for custom fees may be required at the time of delivery.
												</Alert>
											)}
											<Stepper activeStep={activeStep} steps={steps} />
											<Form>
												{activeStep === Steps.Account && <AccountFormDetails showPasswordOption={true} disabledField />}
												{activeStep === Steps.Shipping && (
													<ShippingForm
														setCountry={setCountry}
														country={country}
														setTouchedField={setFieldTouched}
														handleChanged={handleChange}
													/>
												)}
												<FormActions
													back={() => {
														setErrors({});
														setActiveStep(
															prevState => (prevState > Steps.Account ? prevState - 1 : Steps.Account),
															() => validateForm()
														);
													}}
													next={() => {
														resetForm({ values });
														handleNext(values, validateForm);
													}}
													valid={valid}
													loading={isLoading}
													step={activeStep}
													showSignIn={false}
													lastStep={Steps.Shipping}
												/>
											</Form>
										</Box>
									</Box>
								</Card>
								<Summary gift={giftCardData} />
							</>
						);
					}}
				</Formik>
			);
		case Steps.Success:
		case Steps.Error:
			return (
				<SignUpFollowUpScreen
					success={activeStep === Steps.Success}
					error={activeStep === Steps.Error}
					invitationError={invitationError as IErrorResponseData}
					showTryAgainButton={activeStep === Steps.Error}
					onClick={handleClickFollowUp}
					showContinueButton={showContinueButton}
				/>
			);
		default:
			return null;
	}
};

const GiftCardSignUp = () => {
	const dispatch = useAppDispatch();
	const { code } = useParams<{ code: string }>();

	const [activeStep, setActiveStep] = useStateWithCallback<Steps>(Steps.Greeting);

	const { isLoading, invitationError } = useAppSelector(getFullState);

	useEffect(() => {
		if (code) {
			dispatch(verifyGiftCardCode(code)).then(r => {
				if (!r.error) {
					setActiveStep(Steps.Account);
				}
			});
		}
	}, [code, dispatch]);

	if (activeStep === Steps.Greeting && isLoading) {
		return <ContentLoader />;
	}

	if (activeStep === Steps.Greeting) {
		return <GreetingScreen isLoading={isLoading} setActiveStep={setActiveStep} invitationError={invitationError} />;
	}

	return (
		<SignUpLayout logo={Logo}>
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} mt={5}>
				<CardsContainer $activeStep={"column"} display="flex" justifyContent="center">
					<SignUpForm activeStep={activeStep} setActiveStep={setActiveStep} />
				</CardsContainer>
			</Box>
		</SignUpLayout>
	);
};

export default GiftCardSignUp;
