import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BaseFindDto, FindResponse } from "@remar/shared/dist/api/baseApiService";
import { LiveStream } from "@remar/shared/dist/models";

import { GLOBAL_CONSTANTS } from "../../../constants";
import { RootState } from "../../index";
import { liveStreamService } from "../../services/liveStream";

interface LiveStreamState {
	isFetchingHistory: boolean;
	isFetchingStream: boolean;
	streamHistory: FindResponse<LiveStream> | null;
	selectedLiveStream: LiveStream | null;
	selectedLiveStreamViewersCount: number;
	selectedLiveStreamError: string | null;
}

const initialState: LiveStreamState = {
	isFetchingHistory: false,
	isFetchingStream: false,
	streamHistory: null,
	selectedLiveStream: null,
	selectedLiveStreamError: null,
	selectedLiveStreamViewersCount: 0
};

export const fetchLiveStream = createAsyncThunk(
	"liveStream/fetchLiveStream",
	async ({ id }: { id: number }, { rejectWithValue }) => {
		return liveStreamService.findOne(id).catch(e => rejectWithValue(e.message));
	}
);

export const fetchLiveStreamHistory = createAsyncThunk(
	"liveStream/fetchLiveStreamHistory",
	async (options: BaseFindDto, { rejectWithValue }) => {
		return liveStreamService.find(options).catch(e => rejectWithValue(e));
	}
);

export const fetchLiveStreamViewersCount = createAsyncThunk(
	"liveStream/fetchLiveStreamViewersCount",
	async ({ streamId }: { streamId: string }): Promise<{ totalWebRTCWatchersCount: number }> => {
		const res = await fetch(`${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_STATISTICS_URL}/${streamId}/broadcast-statistics`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		});
		return res.json();
	}
);

export const postLiveStreamComment = createAsyncThunk(
	"liveStream/postLiveStreamComment",
	async ({ comment, streamId }: { comment: string; streamId: string }, { rejectWithValue }) => {
		try {
			return await liveStreamService.postComment({ comment, streamId });
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const liveStreamSlice = createSlice({
	name: "liveStream",
	initialState,
	reducers: {
		resetSelectedLiveStream: state => {
			state.selectedLiveStream = null;
		},
		resetSelectedLiveStreamError: state => {
			state.selectedLiveStreamError = null;
		}
	},
	extraReducers: builder =>
		builder
			.addCase(fetchLiveStreamHistory.pending, state => {
				state.isFetchingHistory = true;
			})
			.addCase(fetchLiveStreamHistory.fulfilled, (state, action) => {
				state.isFetchingHistory = false;
				state.streamHistory = action.payload;
			})
			.addCase(fetchLiveStreamHistory.rejected, state => {
				state.isFetchingHistory = false;
			})
			.addCase(fetchLiveStream.pending, state => {
				state.isFetchingStream = true;
			})
			.addCase(fetchLiveStream.fulfilled, (state, action) => {
				state.isFetchingStream = false;
				state.selectedLiveStream = action.payload;
			})
			.addCase(fetchLiveStream.rejected, (state, action) => {
				state.isFetchingStream = false;
				state.selectedLiveStreamError = action.payload as string;
			})
			.addCase(fetchLiveStreamViewersCount.fulfilled, (state, action) => {
				const _count = action.payload.totalWebRTCWatchersCount;
				state.selectedLiveStreamViewersCount = _count > 0 ? _count : 0;
			})
});

export const { resetSelectedLiveStream, resetSelectedLiveStreamError } = liveStreamSlice.actions;
export const getLiveStreamFullState = (state: RootState): LiveStreamState => state.liveStream;

export default liveStreamSlice.reducer;
