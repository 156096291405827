import { Color } from "@material-ui/lab";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISocketNotificationItem, ISocketNotifications, NotificationTypeId } from "@remar/shared/dist/models";

import { AppThunk, RootState } from "store";

import { IBannerData, NotificationItem, NotificationsState } from "./notifications.model";

import { usersService } from "../../services";

const defaultDelay = 3000;

const initialState: NotificationsState = {
	items: [],
	bellNotifications: { items: [] as ISocketNotificationItem[], totalUnread: 0 } as ISocketNotifications,
	bannerNotifications: { items: [] as ISocketNotificationItem[] } as ISocketNotifications,
	announcementNotifications: { items: [] as ISocketNotificationItem[] } as ISocketNotifications,
	loadingBellNotifications: false,
	loadingBannerNotifications: false,
	bannerData: null
};

export const getUserNotifications = createAsyncThunk(
	"notifications/getUserNotifications",
	async (data: { filters: Record<string, unknown>; include?: string[]; findAll?: boolean }, { rejectWithValue }) => {
		const totalUnread = await usersService.getUnreadMessagesCount(data).catch(e => rejectWithValue(e.message));
		const notifications = await usersService
			.getUserNotifications({ ...data, orderBy: { "notification.sendAt": "DESC" } })
			.catch(e => rejectWithValue(e.message));

		return { ...notifications, totalUnread };
	}
);

export const markAsRead = createAsyncThunk(
	"notifications/markAsRead",
	async (
		{
			notificationType = NotificationTypeId.AdminGeneratedPush,
			id
		}: { notificationType?: NotificationTypeId; id?: number },
		{ rejectWithValue }
	) => {
		if (id) {
			return await usersService
				.markNotificationAsRead({
					filters: { "notification.id": id }
				})
				.catch(e => rejectWithValue(e.message));
		}

		return await usersService
			.markNotificationAsRead({
				filters: {
					"notification.notificationTypeId": notificationType
				}
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		fireEvent: (state, action: PayloadAction<NotificationItem>) => {
			state.items = [...state.items, action.payload];
		},
		dismiss: (state, action: PayloadAction<number>) => {
			state.items = state.items.filter(item => item.id !== action.payload);
		},
		dismissAll: state => {
			state.items = [];
		},
		pop: state => {
			const newState = state.items.slice();
			newState.shift();
			state.items = newState;
		},
		pushSocketNotifications: (state, { payload }: PayloadAction<ISocketNotificationItem>) => {
			const {
				notification: { notificationTypeId }
			} = payload;

			if (
				notificationTypeId === NotificationTypeId.AdminGeneratedBanner ||
				notificationTypeId === NotificationTypeId.SystemNotification
			) {
				state.bannerNotifications.items = [...state.bannerNotifications.items, payload];
			} else if (notificationTypeId === NotificationTypeId.AdminGeneratedAnnouncement) {
				state.announcementNotifications.items = [payload, ...state.announcementNotifications.items];
			} else {
				//	push/bell notifications NotificationTypeId.AdminGeneratedPush
				state.bellNotifications.items = [payload, ...state.bellNotifications.items];
				state.bellNotifications.totalUnread += 1;
			}
		},
		setBannerData: (state, { payload }: PayloadAction<IBannerData>) => {
			state.bannerData = payload;
		},
		setBannerActionLoading: (state, { payload }) => {
			if (state.bannerData) state.bannerData.isActionLoading = payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserNotifications.pending, (state, { meta: { arg } }) => {
				const { filters } = arg;
				const types: number[] = filters["notification.notificationTypeId"] as number[];
				const hasOnDemandNotificationsFilter = types.some(
					t => t === NotificationTypeId.AdminGeneratedBanner || t === NotificationTypeId.SystemNotification
				);
				const hasPushNotificationFilter = types.some(t => t === NotificationTypeId.AdminGeneratedPush);

				if (hasOnDemandNotificationsFilter) {
					state.loadingBannerNotifications = true;
				} else if (hasPushNotificationFilter) {
					state.loadingBellNotifications = true;
				}
			})
			.addCase(getUserNotifications.fulfilled, (state, { payload, meta: { arg } }) => {
				const { items: notifications, totalUnread } = payload;
				const { filters } = arg;

				const types: number[] = filters["notification.notificationTypeId"] as number[];

				const hasOnDemandNotificationsFilter = types.some(
					t => t === NotificationTypeId.AdminGeneratedBanner || t === NotificationTypeId.SystemNotification
				);
				const hasPushNotificationFilter = types.some(t => t === NotificationTypeId.AdminGeneratedPush);
				const hasAnnouncementNotificationsFilter = types.some(t => t === NotificationTypeId.AdminGeneratedAnnouncement);

				if (hasOnDemandNotificationsFilter) {
					// admin banners and system banners
					const onDemandNotifications = notifications.filter(
						n =>
							n.notification.notificationTypeId === NotificationTypeId.SystemNotification ||
							n.notification.notificationTypeId === NotificationTypeId.AdminGeneratedBanner
					);
					state.bannerNotifications = { items: onDemandNotifications } as ISocketNotifications;
					state.loadingBannerNotifications = false;
				}

				if (hasPushNotificationFilter) {
					// push/bell notifications
					const pushNotifications = notifications.filter(
						n => n.notification.notificationTypeId === NotificationTypeId.AdminGeneratedPush
					);
					state.bellNotifications = { items: pushNotifications, totalUnread } as ISocketNotifications;
					state.loadingBellNotifications = false;
				}

				if (hasAnnouncementNotificationsFilter) {
					// announcement notifications
					const announcementNotifications = notifications.filter(
						n => n.notification.notificationTypeId === NotificationTypeId.AdminGeneratedAnnouncement
					);
					state.announcementNotifications = { items: announcementNotifications } as ISocketNotifications;
				}
			})
			.addCase(getUserNotifications.rejected, state => {
				state.loadingBannerNotifications = false;
				state.loadingBellNotifications = false;
			})
			.addCase(markAsRead.fulfilled, (state, { meta }) => {
				const { notificationType, id } = meta.arg;

				switch (notificationType) {
					case NotificationTypeId.AdminGeneratedBanner:
						state.bannerNotifications.items = state.bannerNotifications.items.filter(n => n.id !== id);
						break;
					case NotificationTypeId.AdminGeneratedAnnouncement:
						state.announcementNotifications.items = state.announcementNotifications.items.filter(
							item => item.notification.id !== id
						);
						break;
					case NotificationTypeId.AdminGeneratedPush:
						if (id) {
							state.bellNotifications.totalUnread -= 1;
						} else {
							state.bellNotifications.totalUnread = 0;
						}
						break;
				}
			});
	}
});

export const { fireEvent, dismiss, pop, dismissAll, pushSocketNotifications, setBannerData, setBannerActionLoading } =
	notificationsSlice.actions;

// DEPRECATED version, for thunks we need to pass only one argument
export const _emit =
	(message: string, color: Color, preventAutoDismiss = false): AppThunk =>
	(dispatch, getState) => {
		const state = getState();
		const lastItem = state.notifications.items[state.notifications.items.length - 1];
		const lastId = lastItem?.id + 1 || 1;
		dispatch(fireEvent({ message, type: color, id: lastId }));
		if (!preventAutoDismiss) {
			setTimeout(() => {
				dispatch(dismiss(lastId));
			}, defaultDelay);
		}
	};

export const emit = createAsyncThunk(
	"notifications/emit",
	(
		{
			message = "Unknown error",
			color,
			preventAutoDismiss = false
		}: {
			message: string;
			color: Color;
			preventAutoDismiss?: boolean;
		},
		{ dispatch, getState }
	) => {
		const state = getState() as RootState;
		const lastItem = state.notifications.items[state.notifications.items.length - 1];
		const lastId = lastItem?.id + 1 || 1;
		dispatch(fireEvent({ message, type: color, id: lastId }));
		if (!preventAutoDismiss) {
			setTimeout(() => dispatch(dismiss(lastId)), defaultDelay);
		}
	}
);

export const selectNotifications = ({ notifications }: RootState): NotificationItem[] => notifications.items;
export const selectAnnouncementNotifications = ({ notifications }: RootState): ISocketNotifications =>
	notifications.announcementNotifications;
export const getFullState = (state: RootState): NotificationsState => state.notifications;

export default notificationsSlice.reducer;
