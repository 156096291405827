import React, { useEffect, useMemo, useRef } from "react";

import { Box, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded/FilesUploaded";
import { File } from "@remar/shared/dist/models";
import { downloadURI, getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";

import { useAppSelector } from "store";
import { getFullState } from "store/features/QuestionBank/Test/test.slice";

import videojs from "video.js";

import { ReactComponent as YellowHalf } from "assets/icons/icon-half.svg";

import { QuestionRationalInfo, QuestionResult } from "./styles";

import { CorrectAnswer, WrongAnswer, useStyles } from "../styles";

const TutoredTestResultInfo = ({ result }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();
	const videoRef = useRef<HTMLVideoElement>(null);
	const player = React.useRef<videojs.Player>();

	const { rationaleVideos } = useAppSelector(getFullState);

	const rtVideo = useMemo(
		() => rationaleVideos?.find(video => video.id === result?.snapshot?.rationaleVideoId),
		[rationaleVideos, result?.snapshot?.rationaleVideoId]
	);

	useEffect(() => {
		if (rtVideo && !player.current) {
			const videoPlayer = getVideoPlayer(videoRef.current, rtVideo?.videoUrl);

			videoPlayer.on("error", (_, errors) => {
				console.error("Video.js encountered an error", errors);
			});

			player.current = videoPlayer;

			return () => {
				if (player.current) {
					player.current.dispose();
					player.current = null;
				}
			};
		}
	}, [rtVideo]);

	return (
		<>
			<div className={classes.col}>
				<div className={classes.rationaleContainerTutoredTest}>
					{rtVideo && (
						<Box width={"100%"} mb={3}>
							<Typography className={classes.rationaleVideoTitle}>Related Video</Typography>
							<Typography className={classes.rationaleVideoDescription}>
								Watching video will help you choose right answer.
							</Typography>
							<div data-vjs-player>
								<video ref={videoRef} preload={"auto"} className="video-js vjs-big-play-centered vjs-16-9" />
							</div>
						</Box>
					)}

					<div className={classes.rationaleHeader}>
						{result?.percentageGrade === 1 ? (
							<Box display={"flex"} alignItems="center">
								<CorrectAnswer />
								<QuestionResult>Correct</QuestionResult>
							</Box>
						) : result?.percentageGrade === 0 ? (
							<Box display={"flex"} alignItems="center">
								<WrongAnswer className={classes.icon} />
								<QuestionResult>Incorrect </QuestionResult>
							</Box>
						) : (
							<Box display={"flex"} alignItems="center">
								<YellowHalf className={classes.icon} />
								<QuestionResult>Partially Correct</QuestionResult>
							</Box>
						)}
					</div>
					<div className={classes.rationaleHeader}>Rational answer</div>
					<QuestionRationalInfo
						dangerouslySetInnerHTML={{
							__html: result?.snapshot.endOfQuestionSummary ?? ""
						}}
					></QuestionRationalInfo>

					{(result?.snapshot.attachments as File[])?.length > 0 && (
						<Box width={isMobile ? "100%" : "50%"}>
							<FilesUploaded
								attachments={result?.snapshot?.attachments as File[]}
								IconSvg={DownloadIconSvg}
								showTitle={false}
								isDownload
								borderColor="#edf1f7"
								filenameColor="#4d5058"
								onClick={attachment => downloadURI(attachment.fileUrl, attachment.name)}
							/>
						</Box>
					)}
				</div>
			</div>
		</>
	);
};

export default TutoredTestResultInfo;
