export const URLS = {
	ASSETS: "/assets",
	CLIENT_LOG: "/client-log",
	COUNTRIES: "/countries",
	COUPONS: "/coupons",
	COURSES: "/courses",
	FAQ_ITEMS: "/faqs",
	FILEVAULTFILES: "/files",
	FILEVAULTFOLDER: "/folders",
	GET_IMAGE_URL: "/image-url",
	LESSONS: "/lessons",
	LESSON_VIDEOS: "/lesson-videos",
	LOCATIONS: "/locations",
	LOCATION_PACKAGE: "/location-package",
	LIVE_STREAM: "/live-stream",
	NOTES: "/notes",
	QUESTIONS: "/questions",
	QUESTIONS_ATTEMPTS_COUNT: "/question-attempts-count",
	QUESTION_DIFFICULTY_LEVELS_TIERS: "/question-difficulty-level-tiers",
	QUIZZES: "/quizzes",
	SCHOOLS: "/schools",
	SUBJECTS: "/subjects",
	SUBJECT_LESSONS: "/subject-lessons",
	UPLOAD: "/upload",
	USERS: "/users",
	USER_CUSTOM_TESTS: "/user-custom-tests",
	USER_CUSTOM_TEST_TYPES: "/user-custom-test-types",
	USER_LESSON_ATTEMPTS: "/user-lesson-attempts",
	USER_LESSON_FEEDBACK_ITEMS: "/user-lesson-feedbacks",
	USER_LESSON_PROGRESS_ITEMS: "/user-lesson-progress-items",
	USER_PAYMENTS: "/user-payments",
	USER_PAYMENT_CAT: "/users/payment-cat",
	USER_QUESTION_ATTEMPTS: "/user-question-attempts",
	USER_QUESTION_FEEDBACK_ITEMS: "/user-question-feedbacks",
	USER_QUESTION_NOTES: "/user-question-notes",
	USER_QUIZ_ATTEMPTS: "/user-quiz-attempts",
	USER_SUBSCRIPTION_TYPES: "/user-subscription-types"
};
