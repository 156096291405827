import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Stepper from "@remar/shared/dist/components/Stepper";
import { ExternalIntegrationIds, SignupStepsLabels } from "@remar/shared/dist/constants";
import useStateWithCallback from "@remar/shared/dist/hooks/useStateWithCallback";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Country, IErrorResponseData, Integrations } from "@remar/shared/dist/models";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";

import { useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import {
	fetchCountries,
	getFullState as getFullAuthState,
	getInvitationDetails,
	removeBookFromInvitationData,
	signInWithTokenFromResponse,
	studentSignUpInvitation
} from "store/features/Auth/authSlice";
import { USA_COUNTRY_ID } from "store/features/Auth/constants";
import { getFullState as getFullThemeState } from "store/features/Theme/theme.slice";

import logo from "assets/images/logo.svg";

import { routes } from "core/constants";

import Summary from "./Summary";
import { accountInitialValue, paymentInitValue, shippingInitValue } from "./types";

import SignUpLayout from "../SignUpLayout";
import FormActions from "../components/FormActions";
import { AccountFormDetails, PaymentForm, ShippingForm } from "../components/Forms";
import SignUpFollowUpScreen from "../components/SignUpFollowUpScreen";

import { AccountSchema, PaymentSchema, ShippingSchema } from "../components/schemas";

import { CardsContainer, useStyles } from "../components/styles";
import { invitationSignUpFormKeys, validateFormValues } from "../components/utils";

export enum Steps {
	ACCOUNT,
	SHIPPING,
	PAYMENT,
	SUCCESS,
	ERROR
}
const steps = [
	{ label: SignupStepsLabels.account },
	{ label: SignupStepsLabels.shipping },
	{ label: SignupStepsLabels.payment }
];
const Logo: React.ReactNode = () => {
	const { logoImageUrl, isLoading } = useAppSelector(getFullThemeState);
	return (
		<Box display={"flex"} justifyContent={"center"}>
			{isLoading ? (
				<ContentLoader size={"5rem"} height={50} />
			) : (
				<img alt="institution logo" src={logoImageUrl || logo} height={50} />
			)}
		</Box>
	);
};

const InvitationSingUp = () => {
	const { invitationId } = useParams<{ invitationId: string }>();
	const {
		isLoading,
		userSubscriptionTypeAddonIds,
		countries,
		invitationDetails,
		invitationDetailsLoading,
		invitationError,
		selectedCountryId,
		token,
		user
	} = useAppSelector(getFullAuthState);
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const history = useHistory();
	const elements = useElements();
	const stripe = useStripe();
	const [activeStep, setActiveStep] = useStateWithCallback(Steps.ACCOUNT);
	const [showContinueButton, setShowContinueButton] = useState(true);
	const [shouldShowTryAgainBtn, setShouldShowTryAgainBtn] = useState(true);
	const [country, setCountry] = useState<Country>();
	const [physicalBook, setPhysicalBook] = useState<boolean>(false);
	const [freeShipping, setFreeShipping] = useState<boolean>(false);
	const [excludedBooks, setExcludedBooks] = useState<number[]>([]);
	const [totalAmount, setTotalAmount] = useState(0);

	const isCourseFeesCoveredByInstAdmin = useMemo(
		() => !!invitationDetails?.user?.paymentConfiguration?.coverInitial,
		[invitationDetails]
	);

	const initialValues = useMemo(() => {
		if (invitationDetails?.subscriptionData) {
			const { subscriptionData } = invitationDetails;
			const subTypeEIDItems = subscriptionData?.subTypeEIDItems?.find(
				item => item.integrationId === Integrations.StripeIntegrationId && item.parentId === null
			);
			const isPhysicalBook = subTypeEIDItems?.children?.some(
				book => book.integrationId !== Integrations.DigitalAssetIntegrationId
			);
			setPhysicalBook(isPhysicalBook);
		}

		const updatedInitialValue = {
			...accountInitialValue,
			email: invitationDetails?.user.email,
			firstName: invitationDetails?.user.firstName,
			lastName: invitationDetails?.user.lastName
		};
		return {
			...updatedInitialValue,
			...shippingInitValue,
			...paymentInitValue
		};
	}, [invitationDetails]);

	const hasPhysicalBooks = useMemo(
		() =>
			invitationDetails?.subscriptionData?.subTypeEIDItems?.some(
				({ id, counterparts }) =>
					counterparts?.some(({ integrationId }) => integrationId === ExternalIntegrationIds.PBS) &&
					userSubscriptionTypeAddonIds?.includes(id)
			),
		[invitationDetails, userSubscriptionTypeAddonIds]
	);

	const validationSchema = useMemo(() => {
		switch (activeStep) {
			case Steps.ACCOUNT:
				return AccountSchema;
			case Steps.SHIPPING:
				return ShippingSchema(hasPhysicalBooks, countries);
			case Steps.PAYMENT:
				return PaymentSchema;
		}
	}, [activeStep, countries, hasPhysicalBooks]);

	useEffect(() => {
		if (!invitationDetails) return;
		let skipShipmentPayment = invitationDetails.user.paymentConfiguration.coverShipping || !physicalBook;
		if (country && physicalBook) {
			skipShipmentPayment = skipShipmentPayment || country.freeShippingForInstitution;
		}
		setFreeShipping(skipShipmentPayment);
		if (skipShipmentPayment && isCourseFeesCoveredByInstAdmin) {
			steps.some(step => step.label == SignupStepsLabels.payment) && steps.pop();
		} else if (!steps.some(step => step.label == SignupStepsLabels.payment)) {
			steps.push({ label: SignupStepsLabels.payment });
		}
	}, [dispatch, country, physicalBook, isCourseFeesCoveredByInstAdmin, invitationDetails]);

	useEffect(() => {
		if (invitationId) {
			dispatch(
				getInvitationDetails({
					inviteCode: invitationId
				})
			);
		}
	}, [dispatch, history, invitationId]);

	useEffect(() => {
		dispatch(fetchCountries(0));
	}, [dispatch]);

	useEffect(() => {
		if (!invitationError) return;
		setShouldShowTryAgainBtn(false);
		setActiveStep(Steps.ERROR);
	}, [invitationError]);

	useEffect(() => {
		if (user && token) {
			history.replace(routes.signIn.getPath());
		}
	}, [user, token]);

	const handleNext = (values, validateForm = () => {}) => {
		if (
			activeStep === Steps.PAYMENT ||
			(activeStep === Steps.SHIPPING && freeShipping && isCourseFeesCoveredByInstAdmin)
		) {
			handleSubmit(values);
		} else {
			setActiveStep(
				prevState => prevState + 1,
				() => validateForm()
			);
		}
	};

	const handleSubmit = values => {
		dispatch(
			studentSignUpInvitation({
				elements,
				stripe,
				skipPayment: freeShipping && isCourseFeesCoveredByInstAdmin,
				values: { invitationId, ...values, execludedSubTypeEIDItemIds: excludedBooks }
			})
		).then(response => {
			if (response.error) {
				setActiveStep(Steps.ERROR);
			} else {
				setActiveStep(Steps.SUCCESS);
				if (!response.payload.paymentNeedsConfirmation) {
					setShowContinueButton(false);
					dispatch(signInWithTokenFromResponse(response.payload));
				}
			}
		});
	};

	const handleClickFollowUp = () => {
		if (activeStep === 3) {
			// continue clicked
			history.replace(routes.signIn.getPath());
		} else {
			// try again clicked
			setActiveStep(() => {
				const shouldSkipPaymentStep = freeShipping && isCourseFeesCoveredByInstAdmin;
				return shouldSkipPaymentStep ? Steps.SHIPPING : Steps.PAYMENT;
			});
		}
	};

	return invitationDetailsLoading ? (
		<Box display="flex" alignItems="center" justifyContent="center" height={450}>
			<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
		</Box>
	) : (
		<SignUpLayout logo={Logo}>
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} mt={5}>
				<CardsContainer $activeStep={"column"} display="flex" justifyContent="center">
					<Formik
						initialValues={{ ...initialValues }}
						validationSchema={validationSchema}
						validateOnChange
						validateOnBlur={false}
						onSubmit={values => {
							handleNext(values);
						}}
					>
						{({
							isValid,
							values,
							setErrors,
							setFieldValue,
							setTouched,
							setFieldTouched,
							handleChange,
							validateForm,
							dirty,
							errors,
							resetForm
						}) => {
							let valid = isValid && isEmpty(errors) && dirty;
							if (!dirty && activeStep !== Steps.SUCCESS && activeStep !== Steps.ERROR) {
								valid = validateFormValues(invitationSignUpFormKeys[activeStep], values);
							}
							switch (activeStep) {
								case Steps.ACCOUNT:
								case Steps.SHIPPING:
								case Steps.PAYMENT: {
									return (
										<>
											<Card className={`${classes.card} ${classes.baseFlex}`}>
												<Box>
													<Box px={6} pt={2} className={classes.stepperForm}>
														{(activeStep === Steps.SHIPPING || activeStep === Steps.PAYMENT) &&
															selectedCountryId &&
															selectedCountryId !== USA_COUNTRY_ID && (
																<Alert style={{ margin: "16px 0" }} severity="info">
																	Payment for custom fees may be required at the time of delivery.
																</Alert>
															)}
														<Stepper activeStep={activeStep} steps={steps} />
														<Form>
															{activeStep === Steps.ACCOUNT && (
																<AccountFormDetails showPasswordOption={true} disabledField />
															)}
															{activeStep === Steps.SHIPPING && (
																<ShippingForm
																	setCountry={setCountry}
																	country={country}
																	setTouchedField={setFieldTouched}
																	handleChanged={handleChange}
																/>
															)}
															{activeStep === Steps.PAYMENT && (
																<PaymentForm
																	setFieldValue={setFieldValue}
																	setTouched={setTouched}
																	termValue={values.terms}
																	showTerms={true}
																	setTouchedField={setFieldTouched}
																	handleChanged={handleChange}
																	totalAmount={totalAmount}
																/>
															)}
															<FormActions
																back={() => {
																	if (activeStep === Steps.PAYMENT) {
																		setFieldValue("validCardDetails", false);
																		setFieldTouched("validCardDetails", false, false);
																	}
																	setErrors({});
																	setActiveStep(
																		prevState => (prevState > Steps.ACCOUNT ? prevState - 1 : Steps.ACCOUNT),
																		() => {
																			validateForm();
																		}
																	);
																}}
																next={() => {
																	resetForm({ values });
																	handleNext(values, validateForm);
																}}
																valid={valid}
																loading={isLoading}
																step={activeStep}
																showSignIn={false}
																lastStep={
																	freeShipping && isCourseFeesCoveredByInstAdmin ? Steps.SHIPPING : Steps.PAYMENT
																}
															/>
														</Form>
													</Box>
												</Box>
											</Card>
											<Summary
												invitedBy={invitationDetails?.invitedBy}
												paymentConfiguration={invitationDetails?.user.paymentConfiguration}
												subscriptionData={invitationDetails?.subscriptionData}
												country={country}
												excludeBook={bookId => {
													setExcludedBooks(prevState => [...prevState, bookId]);
													dispatch(removeBookFromInvitationData(bookId));
												}}
												shippingCovered={freeShipping}
												setTotalAmount={setTotalAmount}
											/>
										</>
									);
								}
								case Steps.SUCCESS:
								case Steps.ERROR:
									return (
										<SignUpFollowUpScreen
											success={activeStep === Steps.SUCCESS}
											error={activeStep === Steps.ERROR}
											invitationError={invitationError as IErrorResponseData}
											showTryAgainButton={shouldShowTryAgainBtn && activeStep === Steps.ERROR}
											onClick={handleClickFollowUp}
											showContinueButton={showContinueButton}
										/>
									);
								default:
									return;
							}
						}}
					</Formik>
				</CardsContainer>
			</Box>
		</SignUpLayout>
	);
};

export default InvitationSingUp;
