import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";
import { LiveStream } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class LiveStreamService extends BaseApiService<
	LiveStream,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	postComment(data: { comment: string; streamId: string }): Promise<unknown> {
		return request(
			`${this.baseUrl}/comment`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}
}

export const liveStreamService = new LiveStreamService(URLS.LIVE_STREAM);
