import React, { useMemo } from "react";

import {
	Badge,
	Box,
	ClickAwayListener,
	Divider,
	IconButton,
	List,
	ListItem,
	Tooltip,
	Typography,
	useTheme
} from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";

import { ISocketNotificationItem } from "../../models";
import { IExtendedTheme } from "../../theme/default";

/**
 * Props for the BellNotificationsBlock component.
 * @typedef {Object} BellNotificationsBlockProps
 * @property {ISocketNotificationItem[]} bellNotifications - Array of notification items.
 * @property {function} [markAsRead] - Function to mark notifications as read.
 * @property {number} [unreadCount] - Number of unread notifications.
 */
type BellNotificationsBlockProps = {
	bellNotifications: ISocketNotificationItem[];
	markAsRead?: () => void;
	unreadCount?: number;
};

/**
 * BellNotificationsBlock component displays a notification bell icon with a badge showing the number of unread notifications.
 * When clicked, it shows a list of notifications.
 *
 * @param {BellNotificationsBlockProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * const notifications = [
 *   { id: 1, notification: { body: "New message received" }, readAt: null, action: () => console.log("Notification clicked") },
 *   { id: 2, notification: { body: "Your order has been shipped" }, readAt: new Date(), action: () => console.log("Notification clicked") }
 * ];
 *
 * <BellNotificationsBlock
 *   bellNotifications={notifications}
 *   markAsRead={() => console.log("Marked as read")}
 *   unreadCount={1}
 * />
 */
const BellNotificationsBlock = ({
	bellNotifications,
	markAsRead = () => {},
	unreadCount
}: BellNotificationsBlockProps) => {
	const theme = useTheme<IExtendedTheme>();
	const [expanded, setExpanded] = React.useState(false);

	const newItemsNumber = useMemo(
		() => unreadCount ?? bellNotifications.filter(n => !n.readAt).length,
		[bellNotifications, unreadCount]
	);

	return (
		<Tooltip title="Notifications">
			<IconButton
				size="small"
				onClick={() => {
					if (!expanded && newItemsNumber) {
						markAsRead();
					}
					setExpanded(s => !s);
				}}
			>
				<Badge badgeContent={newItemsNumber > 99 ? "99+" : newItemsNumber} color="primary">
					<NotificationsIcon style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
				</Badge>
				{expanded && (
					<ClickAwayListener onClickAway={() => setExpanded(false)}>
						<Box
							style={{
								position: "absolute",
								width: "400px",
								top: "55px",
								borderRadius: "4px",
								background: theme.palette.background.paper,
								boxShadow: "0 0 12px rgba(27,31,35,.15)"
							}}
						>
							<Typography style={{ fontWeight: "bold", textAlign: "start", padding: 10 }} variant={"subtitle1"}>
								Notifications
							</Typography>
							<List style={{ background: theme.palette.background.paper, maxHeight: "350px", overflow: "auto" }}>
								{bellNotifications.length === 0 && (
									<ListItem style={{ justifyContent: "center" }}>No notifications yet</ListItem>
								)}
								{bellNotifications.map((item, index) => (
									<div key={item.id}>
										<ListItem onClick={item.action} style={{ padding: "14px 10px", lineHeight: "1.4" }}>
											{item.notification.body}
										</ListItem>
										{bellNotifications.length > index + 1 && (
											<Divider style={{ backgroundColor: theme.palette.colors.basic[800] }} />
										)}
									</div>
								))}
							</List>
						</Box>
					</ClickAwayListener>
				)}
			</IconButton>
		</Tooltip>
	);
};

export default BellNotificationsBlock;
