import React, { useEffect, useMemo, useRef, useState } from "react";

import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import { Box, Card, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";

import { FileCopy } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Button from "@remar/shared/dist/components/Button";
import ChatComponent, {
	ChatMessage,
	DataTypes,
	WebRTCAdaptorStatus
} from "@remar/shared/dist/components/ChatComponent";
import { Wrapper } from "@remar/shared/dist/layouts";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { uniqBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { selectUser } from "store/features/Auth/authSlice";
import {
	fetchLiveStream,
	fetchLiveStreamViewersCount,
	getLiveStreamFullState,
	postLiveStreamComment,
	resetSelectedLiveStreamError
} from "store/features/LiveStream/LiveStream.slice";
import { emit } from "store/features/notifications/notifications.slice";
import videojs from "video.js";

import { routes } from "core/constants";

import { GLOBAL_CONSTANTS } from "../../../constants";

import { StyledEye, VideoContainer } from "../styles";

const LiveStream = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { id, streamId } = useParams<{ id: string; streamId: string }>();
	const videoRef = useRef<HTMLVideoElement>(null);
	const player = useRef<videojs.Player>(null);
	const adaptorRef = useRef<typeof WebRTCAdaptor>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [chatMessages, setChatMessages] = useState<Array<ChatMessage> | null>(null);

	const { selectedLiveStream, selectedLiveStreamError, selectedLiveStreamViewersCount } =
		useAppSelector(getLiveStreamFullState);
	const user = useAppSelector(selectUser);

	const breadcrumb = useMemo(
		() => [
			{ title: "Live Stream", key: 0, link: routes.streams.getPath() },
			{ title: selectedLiveStream?.name, key: 1 }
		],
		[selectedLiveStream]
	);

	useEffect(() => {
		dispatch(
			fetchLiveStream({
				id
			})
		);
	}, [dispatch, id]);

	useEffect(() => {
		if (selectedLiveStreamError) {
			dispatch(
				emit({
					message: selectedLiveStreamError,
					color: "error"
				})
			);
		}
		return () => {
			dispatch(resetSelectedLiveStreamError());
		};
	}, [dispatch, selectedLiveStreamError]);

	useEffect(() => {
		if (!streamId || !selectedLiveStream || selectedLiveStreamError || selectedLiveStream.hasEnded) return;

		const startPolling = async () => {
			dispatch(fetchLiveStreamViewersCount({ streamId }));
		};

		startPolling();

		const interval = setInterval(() => {
			startPolling();
		}, 5000);

		return () => clearInterval(interval);
	}, [streamId, selectedLiveStreamError, selectedLiveStream, dispatch]);

	useEffect(() => {
		if (!streamId || !selectedLiveStream || adaptorRef.current || selectedLiveStream.hasEnded) return;

		adaptorRef.current = new WebRTCAdaptor({
			websocket_url: `${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_URL}/websocket`,
			mediaConstraints: { video: false, audio: false },
			peerconnection_config: { iceServers: JSON.parse(GLOBAL_CONSTANTS.ICE_SERVERS as string) },
			sdp_constraints: { OfferToReceiveAudio: true, OfferToReceiveVideo: true },
			dataChannelEnabled: true,
			remoteVideoId: "video-player-stream", // The video element ID
			playToken: selectedLiveStream.playToken,
			callback: (info, obj) => {
				// console.log("WebRTCAdaptor Callback: ", info, obj);
				switch (info) {
					case WebRTCAdaptorStatus.INITIALIZED: {
						console.log("WebRTCAdaptor initialized.");
						break;
					}
					case WebRTCAdaptorStatus.STATE_CHANGED: {
						if (obj.state === "checking") {
							setIsLoading(true);
						}
						if (obj.state === "connected") {
							// setIsLoading(false);
						}
						break;
					}
					case WebRTCAdaptorStatus.PLAY_STARTED: {
						console.log("WebRTCAdaptor initialized.");
						console.log("Playing stream:", streamId);
						setChatMessages([]);
						break;
					}
					case WebRTCAdaptorStatus.PLAY_FINISHED: {
						console.log("WebRTCAdaptor initialized.");
						console.log("Stopped playing stream:", streamId);
						break;
					}
					case WebRTCAdaptorStatus.DATA_CHANNEL_OPENED: {
						console.info("Data channel opened");
						break;
					}
					case WebRTCAdaptorStatus.DATA_RECEIVED: {
						const data = JSON.parse(obj.data);
						if (data.dataType === DataTypes.Chat) {
							handleChatMessageReceive(data);
						}
						break;
					}
					case WebRTCAdaptorStatus.DATA_CHANNEL_ERROR: {
						console.error("Data channel error", obj);
						break;
					}
					case WebRTCAdaptorStatus.DATA_CHANNEL_CLOSED: {
						console.info("Data channel closed");
						break;
					}
				}
			},
			callbackError: error => {
				console.error("WebRTC Error: ", error);
			}
		});

		return () => {
			if (adaptorRef.current) {
				adaptorRef.current?.stop(streamId); // Stop stream when component unmounts
			}
		};
	}, [streamId, selectedLiveStream]);

	useEffect(() => {
		if (player.current) {
			isLoading ? player.current.loadingSpinner.show() : player.current.loadingSpinner.hide();
		}
	}, [isLoading]);

	useEffect(() => {
		if (videoRef.current && !player.current && selectedLiveStream) {
			const videoPlayer = selectedLiveStream.hasEnded
				? getVideoPlayer(videoRef!.current, selectedLiveStream!.streamLink)
				: videojs(videoRef!.current);

			videoPlayer.on("error", (_, errors) => {
				console.error("Video.js encountered an error", errors);
			});

			videoPlayer.on("ant-error", (_, errors) => console.error("Error during WebRTC playback:", errors));
			videoPlayer.bigPlayButton.on("click", function () {
				// do the action
				console.log("Player clicked");
				if (adaptorRef.current) {
					console.log("attempt live strem to play");
					adaptorRef.current.play(streamId, selectedLiveStream.playToken);
				} else if (selectedLiveStream?.hasEnded) {
					console.log("attempt past live stream to play");
					videoPlayer.play();
				}
			});
			videoPlayer.on("play", () => {
				console.log("Player played");
				setIsLoading(false);
			});
			videoPlayer.on("pause", () => {
				console.log("Player pause");
				// When user pauses, stop live stream
				if (adaptorRef.current) {
					adaptorRef.current.stop(streamId);
				}
			});

			// Handle Small Play Button (bottom left)
			const smallPlayButton = videoPlayer.controlBar.playToggle.el();
			if (smallPlayButton) {
				smallPlayButton.addEventListener("click", event => {
					event.preventDefault();
					event.stopPropagation();

					console.log("Small Play button clicked, restarting stream...");
					if (adaptorRef.current) {
						adaptorRef.current.play(streamId, selectedLiveStream.playToken);
					} else if (selectedLiveStream?.hasEnded) {
						videoPlayer.play();
					}
				});
			}

			player.current = videoPlayer;
		}

		return () => {
			if (player.current) {
				player.current = null;
			}
		};
	}, [videoRef, streamId, selectedLiveStream]);

	const handleChatMessageReceive = (data: ChatMessage) => {
		setChatMessages(c => {
			const n = uniqBy([...c, data], "id");
			return n.sort((a, b) => {
				return new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime();
			});
		});
	};

	const handleSendWebRtcData = async (data: ChatMessage) => {
		dispatch(postLiveStreamComment({ streamId, comment: data.message }));
	};

	return (
		<Wrapper
			heading="Stream"
			breadcrumb={breadcrumb}
			actions={
				<Box>
					<Button variant={"filled"} color={"info"} onClick={() => history.goBack()} disabled={false} loading={false}>
						Back
					</Button>
				</Box>
			}
		>
			{selectedLiveStream?.hasEnded && (
				<Alert style={{ margin: "16px 0" }} severity="info">
					Live event is no longer available.
				</Alert>
			)}

			<Grid container spacing={1}>
				<Grid item xs={12} lg={9}>
					<VideoContainer data-vjs-player>
						<video
							ref={videoRef}
							id="video-player-stream"
							preload="auto"
							className={"video-js vjs-big-play-centered vjs-default-skin"}
							controls
						/>
						{isLoading && (
							<Box className={"loader"}>
								<CircularProgress size="2rem" color="inherit" thickness={2} variant="indeterminate" />
							</Box>
						)}
					</VideoContainer>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Box display={"flex"} flexDirection={"column"} gridGap={5}>
						<Card>
							<TextField
								variant="outlined"
								fullWidth
								value={`${window.location.origin}/streams/${id}/${streamId}`}
								InputProps={{
									readOnly: true,
									endAdornment: (
										<Box ml={2}>
											<FileCopy
												style={{ cursor: "pointer" }}
												onClick={async () => {
													await navigator.clipboard.writeText(`${window.location.origin}/streams/${id}/${streamId}`);
													dispatch(
														emit({
															message: "Stream link copied to clipboard",
															color: "success"
														})
													);
												}}
											/>
										</Box>
									)
								}}
							/>
						</Card>
						<Card>
							<Box>
								<Typography>Stream Details</Typography>
								<Box mt={5} display={"flex"} flexDirection={"column"}>
									<Box display={"flex"} alignItems={"center"} gridGap={10}>
										<StyledEye /> {selectedLiveStreamViewersCount}
									</Box>
								</Box>
							</Box>
						</Card>
					</Box>
				</Grid>
				<ChatComponent
					chatMessages={chatMessages}
					handleChatMessageReceive={handleChatMessageReceive}
					handleSendWebRtcData={handleSendWebRtcData}
					user={user}
				/>
			</Grid>
		</Wrapper>
	);
};

export default LiveStream;
